import React from 'react'
import { ASSETS_VULNERABILITIES_FILTERS_MAP } from 'ui-v2/src/lib/constants/assets.constant'

import RegionFilter from '../../assets-threats/filters/region'
import RiskFilter from './risk'
import VolumeIdFilter from './volume'
import InstanceIdFilter from './instance'
import MisconfigurationFilter from './misconfigurations'
import AccountIdFilter from './account'
import InstanceMisconfigurationFilter from './instanceMisconfigurations'

interface FilterComponentProps {
  options: Array<string>
}

const createFilterOption = (
  Component: React.ComponentType<FilterComponentProps>
) => {
  return Component
}

export const filterOptions = {
  [ASSETS_VULNERABILITIES_FILTERS_MAP.ACCOUNT_ID]:
    createFilterOption(AccountIdFilter),
  [ASSETS_VULNERABILITIES_FILTERS_MAP.INSTANCE_ID]:
    createFilterOption(InstanceIdFilter),
  [ASSETS_VULNERABILITIES_FILTERS_MAP.VOLUME_ID]:
    createFilterOption(VolumeIdFilter),
  [ASSETS_VULNERABILITIES_FILTERS_MAP.REGION]: createFilterOption(RegionFilter),
  [ASSETS_VULNERABILITIES_FILTERS_MAP.RISK]: createFilterOption(RiskFilter),
  [ASSETS_VULNERABILITIES_FILTERS_MAP.MISCONFIGURATION]: createFilterOption(
    MisconfigurationFilter
  ),
  [ASSETS_VULNERABILITIES_FILTERS_MAP.INSTANCE_MISCONFIGURATION]:
    createFilterOption(InstanceMisconfigurationFilter),
}
