import { useMemo } from 'react'
import { Stack } from '@mui/material'
import { useAssetsLiveQuery } from 'ui-v2/src/hooks/queries/assets'
import { AssetsS3TableData } from 'ui-v2/src/lib/use-cases/assets'
import { getS3Columns } from './column-config'
import AssetFilters from '../filters'
import { ASSET_FILTERS_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import { useSearchParams } from 'react-router-dom'
import { useAssetsS3Filters } from 'ui-v2/src/hooks/filters/assets'
import CursorTable from 'ui-v2/src/components/ui/data-table/cursor-pagination-table'
import { useAssetsSearchQuery } from 'ui-v2/src/hooks/queries/search'
import { useSortParams } from 'ui-v2/src/hooks/sort-params'
import { convertS3SortConfigsToProto } from './sort-mapping'

const AssetsS3 = () => {
  const { sortConfigs } = useSortParams()

  const [searchParams] = useSearchParams()

  const { filters, setPageSize, setPageToken } = useAssetsS3Filters()

  const { data: assetsLiveData, isLoading: isAssetsLiveDataLoading } =
    useAssetsLiveQuery()

  const { data: assetsS3Data, isLoading: isAssetsS3DataLoading } =
    useAssetsSearchQuery({
      s3: {
        ...filters,
        ...convertS3SortConfigsToProto(sortConfigs),
      },
      pageToken: filters.pageToken,
      pageSize: filters.pageSize,
    })

  const malwareScans = useMemo(
    () =>
      assetsLiveData?.scans.flatMap((scan) => {
        if (scan.malwareScan) {
          return scan.malwareScan
        }
        return []
      }),
    [assetsLiveData]
  )

  const data = useMemo(() => {
    if (!assetsS3Data || !assetsLiveData || !malwareScans) {
      return undefined
    }

    return new AssetsS3TableData({
      s3Buckets: assetsS3Data.bucketsList,
      scans: malwareScans,
      lastBackups: assetsLiveData.lastBackups.backups,
      threats: assetsLiveData.threats,
    }).getS3TableData()
  }, [assetsS3Data, assetsLiveData, malwareScans])

  const columns = useMemo(getS3Columns, [])

  return (
    <Stack spacing={1}>
      <AssetFilters
        showSearch
        filters={[
          ASSET_FILTERS_MAP.BUCKET_NAME,
          ASSET_FILTERS_MAP.BUCKET_ID,
          ASSET_FILTERS_MAP.ACCOUNT_ID,
          ASSET_FILTERS_MAP.REGION,
          ASSET_FILTERS_MAP.BUCKET_STATE,
          ASSET_FILTERS_MAP.POLICY_COVERAGE,
        ]}
      />
      <CursorTable
        key={searchParams.toString()}
        data={data}
        columns={columns}
        isLoading={isAssetsS3DataLoading || isAssetsLiveDataLoading}
        pageSize={filters.pageSize}
        pageToken={filters.pageToken}
        paginationCursors={assetsS3Data?.pagination}
        setPageSize={setPageSize}
        setPageToken={setPageToken}
      />
    </Stack>
  )
}

export default AssetsS3
