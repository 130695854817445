/* eslint-disable import/no-extraneous-dependencies */
import { useMemo } from 'react'
import { Stack } from '@mui/material'
import { useAssetsLiveQuery } from 'ui-v2/src/hooks/queries/assets'
import { useAssetItemsQuery } from 'ui-v2/src/hooks/queries/assets'
import { AssetsGenericHostTableData } from 'ui-v2/src/lib/use-cases/assets'
import { ThreatStatus } from 'blues-corejs/dist/models/threats/types'
import { getGenericHostColumns } from './column-config'
import { ASSET_FILTERS_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import AssetFilters from '../filters'
import { useSearchParams } from 'react-router-dom'
import { useAssetsGenericHostFilters } from 'ui-v2/src/hooks/filters/assets'
import CursorTable from 'ui-v2/src/components/ui/data-table/cursor-pagination-table'
import { useAssetsSearchQuery } from 'ui-v2/src/hooks/queries/search'
import { useSortParams } from 'ui-v2/src/hooks/sort-params'
import { convertHostSortConfigsToProto } from './sort-mapping'

const AssetsGenericHost = () => {
  const { sortConfigs } = useSortParams()

  const [searchParams] = useSearchParams()

  const { filters, setPageSize, setPageToken } = useAssetsGenericHostFilters()

  const {
    data: assetsGenericHostData,
    isLoading: isAssetsGenericHostDataLoading,
  } = useAssetsSearchQuery({
    host: {
      ...filters,
      ...convertHostSortConfigsToProto(sortConfigs),
    },
    pageToken: filters.pageToken,
    pageSize: filters.pageSize,
  })

  const { data: assetItemsData, isLoading: isAssetItemsDataLoading } =
    useAssetItemsQuery(
      {
        assetIdList: assetsGenericHostData?.hostsList.map((host) => host.id),
        pageSize: 1000,
      },
      { enabled: !!assetsGenericHostData }
    )

  const { data: assetsLiveData, isLoading: isLiveAssetsDataLoading } =
    useAssetsLiveQuery()

  const lastScans = useMemo(
    () =>
      assetsLiveData?.scans.flatMap((scan) => {
        if (scan.ransomwareScan) {
          return scan.ransomwareScan
        }
        if (scan.malwareScan) {
          return scan.malwareScan
        }
        if (scan.filesystemCheckScan) {
          return scan.filesystemCheckScan
        }
        return []
      }),
    [assetsLiveData]
  )

  const data = useMemo(() => {
    if (
      !assetsGenericHostData ||
      !assetsLiveData ||
      !lastScans ||
      !assetItemsData
    ) {
      return undefined
    }

    return new AssetsGenericHostTableData({
      genericHosts: assetsGenericHostData.hostsList,
      threats: assetsLiveData.threats.filter(
        (threat) => threat.status === ThreatStatus.DETECTED
      ),
      assetItemsData,
      scans: lastScans,
    }).getGenericHostTableData()
  }, [assetsGenericHostData, assetsLiveData, lastScans, assetItemsData])

  const columns = useMemo(getGenericHostColumns, [])

  const isLoading =
    isAssetsGenericHostDataLoading ||
    isAssetItemsDataLoading ||
    isLiveAssetsDataLoading

  return (
    <Stack spacing={1}>
      <AssetFilters filters={[ASSET_FILTERS_MAP.HOST]} />
      <CursorTable
        key={searchParams.toString()}
        data={data}
        columns={columns}
        isLoading={isLoading}
        pageSize={filters.pageSize}
        pageToken={filters.pageToken}
        paginationCursors={assetsGenericHostData?.pagination}
        setPageSize={setPageSize}
        setPageToken={setPageToken}
      />
    </Stack>
  )
}

export default AssetsGenericHost
