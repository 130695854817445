import { useMemo } from 'react'
import { Stack } from '@mui/material'
import { useAssetsLiveQuery } from 'ui-v2/src/hooks/queries/assets'
import { AssetsEfsTableData } from 'ui-v2/src/lib/use-cases/assets'
import { getEfsColumns } from './column-config'
import AssetFilters from '../filters'
import { ASSET_FILTERS_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import { useSearchParams } from 'react-router-dom'
import { useAssetsEfsFilters } from 'ui-v2/src/hooks/filters/assets'
import CursorTable from 'ui-v2/src/components/ui/data-table/cursor-pagination-table'
import { useAssetsSearchQuery } from 'ui-v2/src/hooks/queries/search'
import { useSortParams } from 'ui-v2/src/hooks/sort-params'
import { convertEfsSortConfigsToProto } from './sort-mapping'

const AssetsEfs = () => {
  const { sortConfigs } = useSortParams()

  const [searchParams] = useSearchParams()

  const { filters, setPageSize, setPageToken } = useAssetsEfsFilters()

  const { data: assetsLiveData, isLoading: isAssetsLiveDataLoading } =
    useAssetsLiveQuery()

  const { data: assetsEfsData, isLoading: isAssetsEfsDataLoading } =
    useAssetsSearchQuery({
      efs: {
        ...filters,
        ...convertEfsSortConfigsToProto(sortConfigs),
      },
      pageToken: filters.pageToken,
      pageSize: filters.pageSize,
    })

  const lastScans = useMemo(
    () =>
      assetsLiveData?.scans.flatMap((scan) => {
        if (scan.ransomwareScan) {
          return scan.ransomwareScan
        }
        if (scan.malwareScan) {
          return scan.malwareScan
        }
        if (scan.filesystemCheckScan) {
          return scan.filesystemCheckScan
        }
        return []
      }),
    [assetsLiveData]
  )

  const data = useMemo(() => {
    if (!assetsEfsData || !assetsLiveData || !lastScans) {
      return undefined
    }

    return new AssetsEfsTableData({
      filesystems: assetsEfsData.filesystemsList,
      scans: lastScans,
      lastBackups: assetsLiveData.lastBackups.backups,
      threats: assetsLiveData.threats,
    }).getEfsTableData()
  }, [assetsEfsData, assetsLiveData, lastScans])

  const columns = useMemo(getEfsColumns, [])

  return (
    <Stack spacing={1}>
      <AssetFilters
        showSearch
        filters={[
          ASSET_FILTERS_MAP.FILESYSTEM_NAME,
          ASSET_FILTERS_MAP.FILESYSTEM_ID,
          ASSET_FILTERS_MAP.ACCOUNT_ID,
          ASSET_FILTERS_MAP.REGION,
          ASSET_FILTERS_MAP.FILESYSTEM_STATE,
          ASSET_FILTERS_MAP.POLICY_COVERAGE,
        ]}
      />
      <CursorTable
        key={searchParams.toString()}
        data={data}
        columns={columns}
        isLoading={isAssetsEfsDataLoading || isAssetsLiveDataLoading}
        pageSize={filters.pageSize}
        pageToken={filters.pageToken}
        paginationCursors={assetsEfsData?.pagination}
        setPageSize={setPageSize}
        setPageToken={setPageToken}
      />
    </Stack>
  )
}

export default AssetsEfs
