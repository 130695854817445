import React from 'react'
/* eslint-disable import/no-extraneous-dependencies */
import Link from 'ui-v2/src/components/ui/data-display/link'
import StatusChip from 'ui-v2/src/components/ui/data-display/chip'
import { IconButton, Stack } from '@mui/material'
import { ContentCopy } from '@mui/icons-material'
import { copyToClipboard } from 'ui-v2/src/lib/helpers/system.helper'
import { LangAwsRegionConstant } from 'ui-v2/src/lib/constants/lang.constant'
import { formatDateToUtc } from 'ui-v2/src/lib/helpers/time.helper'
import { S3BucketThreatsOutput } from 'blues-corejs/dist/use_cases/list_s3buckets_with_threats/types'
import { Column } from 'ui-v2/src/components/ui/data-table/base-table'
import LongTextTooltip from 'ui-v2/src/components/ui/data-display/long-text-tooltip'

export const getS3ThreatColumns = (): Array<Column<S3BucketThreatsOutput>> => [
  {
    key: 'isInfected',
    header: 'Health',
    render: (row) => <StatusChip type={row.isInfected ? 'error' : 'success'} />,
    width: 80,
    align: 'center',
  },
  {
    key: 's3AwsId',
    header: 'Bucket Name',
    render: (row) => (
      <Link to={`/${row.s3Id}`} preservePath preserveSearchParams>
        {row.s3AwsId}
      </Link>
    ),
  },
  {
    key: 'threats',
    header: 'Threats',
    render: (row) => {
      const text = [...new Set(row.threats.map((threat) => threat.name))].join(
        ', '
      )
      return (
        <LongTextTooltip
          text={text}
          placement={'bottom-start'}
          fontSize="0.875rem"
        />
      )
    },
    wrap: true,
  },
  {
    key: 'accountId',
    header: 'Account',
    render: (row) => (
      <Stack direction={'row'} alignItems={'center'} gap={1}>
        {row.accountId}
        <IconButton
          onClick={() => copyToClipboard(row.accountId)}
          size="small"
          sx={{
            padding: '6px',
            '& svg': { fontSize: 16 },
          }}
        >
          <ContentCopy />
        </IconButton>
      </Stack>
    ),
  },
  {
    key: 'region',
    header: 'Region',
    render: (row) => {
      const region = LangAwsRegionConstant.get(row.region)
      return region?.en?.single ?? ''
    },
  },
  {
    key: 'lastScan',
    header: 'Last Scan',
    render: (row) => formatDateToUtc(row.lastScan),
  },
  {
    key: 'status',
    header: 'Status',
    // render: (row) => BUCKET_STATE_MAP[row.status],
  },
]
