import Link from 'ui-v2/src/components/ui/data-display/link'
import { Column } from 'ui-v2/src/components/ui/data-table/base-table'
import { OvaServerTableRow } from 'ui-v2/src/lib/models/assets/ova-server'

export const getOvaServerColumns = (): Array<Column<OvaServerTableRow>> => [
  {
    key: 'name',
    header: 'Servers',
    render: (row) => (
      <Link to={`/${row.id}`} preservePath preserveSearchParams>
        {row.name}
      </Link>
    ),
  },
  {
    key: 'ovaAccountId',
    header: 'Account',
    render: (row) => (
      <Link to={`/${row.id}`} preservePath preserveSearchParams>
        {row.ovaAccountId}
      </Link>
    ),
    sortable: true,
  },
  {
    key: 'backupProvider',
    header: 'Backup Vendor',
    sortable: true,
  },
]
