import React from 'react'
import { Box, Tooltip, useTheme } from '@mui/material'
import { ThemeMode } from '../../common/theme-switcher'

interface LongTextTooltipProps {
  text: string
  maxLength?: number
  customClass?: string
  placement?: 'bottom' | 'left' | 'right' | 'top' | 'top-start' | 'bottom-start'
  customPopperClass?: string
  children?: string | React.ReactNode
  show?: boolean
  tooltipText?: string
  fontSize?: string
}

const LongTextTooltip: React.FC<LongTextTooltipProps> = ({
  text,
  placement,
  maxLength = 20,
  fontSize = '1rem',
  ...props
}) => {
  const theme = useTheme()

  const sliceText = text.toString().slice(0, maxLength)
  return (
    <Tooltip
      {...props}
      title={text}
      placement={placement}
      slotProps={{
        tooltip: {
          sx: {
            color:
              theme.palette.mode === ThemeMode.DARK ? '#D3D8DE' : '#727883',
            backgroundColor:
              theme.palette.mode === ThemeMode.DARK ? '#19232F' : '#F6F9FC',
            border:
              theme.palette.mode === ThemeMode.DARK
                ? '1px solid #4E5665'
                : '1px solid #D3D8DE',
            borderRadius: '6px',
            fontSize: fontSize,
          },
        },
      }}
    >
      <Box sx={{ fontSize: fontSize }}>
        {text.length > maxLength ? sliceText.concat('...') : sliceText}
      </Box>
    </Tooltip>
  )
}

export default LongTextTooltip
