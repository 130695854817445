/* eslint-disable import/no-extraneous-dependencies */
import {
  EBSState,
  EC2State,
  OvaBackupProvider,
  RiskLevel,
} from 'blues-corejs/dist'
import { PolicyCoverageState } from '../models/assets'
import { S3BucketState } from 'blues-corejs/dist/models/assets/aws/s3-bucket/types'
import { EfsState } from 'blues-corejs/dist/models/assets/aws/efs/types'
import { ListEbsRequest } from '../grpc'

export const EC2_STATE_MAP = {
  [EC2State.RUNNING]: 'Running',
  [EC2State.STOPPED]: 'Stopped',
  [EC2State.TERMINATED]: 'Terminated',
  [EC2State.UNKNOWN]: 'Unknown',
} as const

export const EBS_STATE_MAP = {
  [EBSState.CREATING]: 'Creating',
  [EBSState.AVAILABLE]: 'Available',
  [EBSState.INUSE]: 'In Use',
  [EBSState.DELETING]: 'Deleting',
  [EBSState.DELETED]: 'Deleted',
  [EBSState.ERROR]: 'Error',
  [EBSState.UNKNOWN]: 'Unknown',
} as const

export const VOLUME_STATE_FILTER_MAP = {
  [ListEbsRequest.Initial.Filter.VolumeState.CREATING]: 'Creating',
  [ListEbsRequest.Initial.Filter.VolumeState.AVAILABLE]: 'Available',
  [ListEbsRequest.Initial.Filter.VolumeState.IN_USE]: 'In Use',
  [ListEbsRequest.Initial.Filter.VolumeState.ERROR]: 'Error',
  [ListEbsRequest.Initial.Filter.VolumeState.DELETING]: 'Deleting',
  [ListEbsRequest.Initial.Filter.VolumeState.DELETED]: 'Deleted',
  [ListEbsRequest.Initial.Filter.VolumeState.UNKNOWN]: 'Unknown',
} as const

export const BUCKET_STATE_MAP = {
  [S3BucketState.IN_USE]: 'In Use',
  [S3BucketState.DELETED]: 'Deleted',
  [S3BucketState.UNKNOWN]: 'Unknown',
} as const

export const BUCKET_STATE_FILTER_MAP = {
  [S3BucketState.IN_USE]: 'In Use',
  [S3BucketState.DELETED]: 'Deleted',
} as const

export const EFS_STATE_MAP = {
  [EfsState.IN_USE]: 'In Use',
  [EfsState.DELETED]: 'Deleted',
  [EfsState.UNKNOWN]: 'Unknown',
} as const

export const FILESYSTEM_STATE_FILTER_MAP = {
  [S3BucketState.IN_USE]: 'In Use',
  [S3BucketState.DELETED]: 'Deleted',
} as const

export const POLICY_COVERAGE_STATE_MAP = {
  [PolicyCoverageState.COVERED]: 'Covered',
  [PolicyCoverageState.NOT_COVERED]: 'Not Covered',
} as const

export const ASSET_FILTERS_MAP = {
  INSTANCE_ID: 'instance',
  INSTANCE_NAME: 'instance-name',
  INSTANCE_STATE: 'instance-state',
  VOLUME_ID: 'volume',
  VOLUME_NAME: 'volume-name',
  VOLUME_STATE: 'volume-state',
  ACCOUNT_ID: 'account',
  BUCKET_ID: 'bucket',
  BUCKET_NAME: 'bucket-name',
  BUCKET_STATE: 'bucket-state',
  FILESYSTEM_ID: 'filesystem',
  FILESYSTEM_NAME: 'filesystem-name',
  FILESYSTEM_STATE: 'filesystem-state',
  BACKUP_VENDOR: 'backup-vendor',
  OVA_ACCOUNT_ID: 'ova-account',
  HOST: 'host',
  REGION: 'region',
  POLICY_COVERAGE: 'policy',
} as const

export const SERVER_STATUS_SEARCH_PARAM = 'server-status'

export const ASSETS_THREATS_FILTERS_MAP = {
  ACCOUNT_ID: 'accountId',
  REGION: 'regions',
  RANSOMWARE: 'ransomware',
  MALWARE: 'malware',
  FILE_SYSTEM_INTEGRITY: 'filesystem',
} as const

export const INVENTORY_TABLES_FILTERS_MAP = {
  ACCOUNT_ID: 'accountId',
  REGION: 'regions',
  POLICY: 'covered',
} as const

export const ENCRYPTION_ACTIVITY_FILTERS_MAP = {
  ACCOUNT_ID: 'accountId',
  REGION: 'regions',
  POLICY: 'covered',
} as const

export const SEARCH_PARAM = 'search' as const

export const INVENTORY_VIEW_PARAM = 'view' as const

export const INVENTORY_VIEW_PARAM_VALUES = {
  CLEAN: 'clean',
  RPO: 'rpo',
} as const

export const NAME_TAG_KEY = 'Name' as const

export const BACKUP_VENDOR_MAP = {
  [OvaBackupProvider.VEEAM]: 'Veeam',
  [OvaBackupProvider.COMMVAULT]: 'Commvault',
  [OvaBackupProvider.RUBRIK]: 'Rubrick',
  [OvaBackupProvider.COHESITY]: 'Cohesity',
  [OvaBackupProvider.VERITAS_NET_BACKUP]: 'VeritasNetBackup',
  [OvaBackupProvider.AWS_BACKUP_VMWARE]: 'AwsBackupVmware',
} as const

export const ASSET_LIST_KIND = {
  EC2: 'ec2_instance',
} as const

export const ASSETS_VULNERABILITIES_FILTERS_MAP = {
  ACCOUNT_ID: 'accountId',
  REGION: 'regions',
  VOLUME_ID: 'volumeAwsIds',
  INSTANCE_ID: 'attachedInstancesAwsIds',
  MISCONFIGURATION: 'snapshotVulnerabilityTypes',
  INSTANCE_MISCONFIGURATION: 'ebsVulnerabilities',
  RISK: 'riskType',
} as const

export const RISK_LEVEL = {
  [RiskLevel.High]: 'High',
  [RiskLevel.Medium]: 'Medium',
  [RiskLevel.Low]: 'Low',
} as const

export enum AssetKind {
  UNDEFINED = -1,
  AWS_EC2 = 0,
  AZURE_VM = 1,
  VM_WARE_VM = 2,
  AWS_EBS = 3,
  AWS_EFS = 4,
  GENERIC_HOST = 5,
  GENERIC_FS = 6,
  AWS_S3 = 7,
  AWS_S3_OBJECTS = 8,
}
