import { useSearchParams } from 'react-router-dom'
import { ASSETS_THREATS_FILTERS_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import { ValueInterface } from 'ui-v2/src/lib/engine-types'
import { useMemo } from 'react'

type FilterMapKeys =
  (typeof ASSETS_THREATS_FILTERS_MAP)[keyof typeof ASSETS_THREATS_FILTERS_MAP]

export const SEARCH_PARAM_TO_FILTER_NAME_MAP: Partial<
  Record<FilterMapKeys, string>
> = {
  [ASSETS_THREATS_FILTERS_MAP.RANSOMWARE]: 'ransomwares',
  [ASSETS_THREATS_FILTERS_MAP.FILE_SYSTEM_INTEGRITY]: 'fsCheck',
  [ASSETS_THREATS_FILTERS_MAP.MALWARE]: 'malware',
}

export const useAssetsThreatsFilters = () => {
  const [searchParams] = useSearchParams()

  return useMemo(() => {
    const filters: Array<ValueInterface> = []

    Object.values(ASSETS_THREATS_FILTERS_MAP).forEach((filterKey) => {
      const values = searchParams.getAll(filterKey)

      if (values.length > 0) {
        filters.push({
          name: SEARCH_PARAM_TO_FILTER_NAME_MAP[filterKey] ?? filterKey,
          options: values
            .join(',')
            .split(',')
            .filter(Boolean)
            .map((value) => ({
              name: value.trim(),
            })),
        })
      }
    })

    return filters
  }, [searchParams])
}
