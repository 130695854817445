import React, { useEffect, useRef } from 'react'
import * as echarts from 'echarts'
import { Box } from '@mui/material'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import {
  BASE_ROUTE_SEGMENTS,
  DASHBOARD_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'
import {
  INVENTORY_VIEW_PARAM,
  INVENTORY_VIEW_PARAM_VALUES,
} from 'ui-v2/src/lib/constants/assets.constant'
import { BackupType } from 'ui-v2/src/lib/models/assets/live'

const AreaChart = ({ lastBackups }: { lastBackups: Array<BackupType> }) => {
  const areachartRef = useRef(null)
  const navigate = useNavigate()

  useEffect(() => {
    if (!areachartRef.current || !lastBackups.length) {
      return
    }

    const Areachart = echarts.init(areachartRef.current)
    const now = dayjs()

    const ages = lastBackups.map((backup) =>
      now.diff(dayjs(backup.timestamp), 'hour')
    )
    const maxAgeHours = Math.max(...ages)
    const maxAgeDays = Math.ceil(maxAgeHours / 24)

    const backupsPerDay = new Array(maxAgeDays + 1).fill(0)
    lastBackups.forEach((backup) => {
      const ageInDays = Math.floor(
        now.diff(dayjs(backup.timestamp), 'hour') / 24
      )
      if (ageInDays <= maxAgeDays) {
        backupsPerDay[ageInDays]++
      }
    })

    const smoothDistribution = []
    const sigma = 0.5

    for (let i = 0; i <= maxAgeDays; i++) {
      let weightedSum = 0
      let totalWeight = 0

      for (let j = Math.max(0, i - 3); j <= Math.min(maxAgeDays, i + 3); j++) {
        const distance = i - j
        const weight = Math.exp(-(distance * distance) / (2 * sigma * sigma))
        weightedSum += backupsPerDay[j] * weight
        totalWeight += weight
      }

      const smoothedValue = weightedSum / totalWeight
      smoothDistribution.push([i, smoothedValue])
    }

    const totalAssets = lastBackups.length

    const formatAge = (hours: number) => {
      if (hours < 24) {
        return `${hours} Hour${hours !== 1 ? 's' : ''}`
      }
      const days = Math.floor(hours / 24)
      return `${days} Day${days !== 1 ? 's' : ''}`
    }

    const option = {
      tooltip: {
        trigger: 'axis',
        formatter: function (params: any) {
          const dayIndex = params[0].data[0]
          // Get the actual backup count for this day instead of using the smoothed value
          const actualCount = backupsPerDay[dayIndex]
          const percentage = ((actualCount / totalAssets) * 100).toFixed(1)
          return `Day ${dayIndex}<br/>
                 Backups: ${actualCount}<br/>
                 Percentage: ${percentage}%`
        },
        backgroundColor: 'rgba(50, 50, 50, 0.9)',
        borderColor: '#68A9A5',
        textStyle: {
          color: '#fff',
        },
      },
      xAxis: {
        type: 'value',
        min: 0,
        max: maxAgeDays,
        splitLine: { show: false },
        axisLine: { show: false },
        axisTick: { show: false },
        axisLabel: { show: false },
      },
      yAxis: {
        type: 'value',
        min: 0,
        max: Math.ceil(Math.max(...backupsPerDay)), // Use actual max count instead of smoothed values
        splitLine: { show: false },
        axisLine: { show: false },
        axisTick: { show: false },
        axisLabel: { show: false },
      },
      series: [
        {
          type: 'line',
          smooth: true,
          data: smoothDistribution,
          areaStyle: {
            color: '#68A9A5',
            opacity: 0.6,
          },
          lineStyle: {
            width: 2,
            color: '#68A9A5',
          },
          showSymbol: false,
          emphasis: {
            focus: 'series',
          },
        },
      ],
      graphic: [
        {
          type: 'text',
          left: 'center',
          top: '0%',
          style: {
            text: `${totalAssets} Assets`,
            fill: '#FFFFFF',
            font: 'bold 14px Arial',
            textAlign: 'center',
          },
          onclick: function () {
            navigate(
              `/${BASE_ROUTE_SEGMENTS.DASHBOARD}/${DASHBOARD_ROUTE_SEGMENTS.INVENTORY_TABLES}?${INVENTORY_VIEW_PARAM}=${INVENTORY_VIEW_PARAM_VALUES.RPO}`
            )
          },
        },
        {
          type: 'text',
          left: '5%',
          bottom: '10%',
          style: {
            text: '0 Hours\nMin, Age of\nRecovery Point',
            fill: '#FFFFFF',
            font: '12px Arial',
            textAlign: 'left',
          },
        },
        {
          type: 'text',
          right: '5%',
          bottom: '10%',
          style: {
            text: `${formatAge(maxAgeHours)}\nMax, Age of\nRecovery Point`,
            fill: '#E74C3C',
            font: '12px Arial',
            textAlign: 'right',
          },
        },
      ],
    }

    Areachart.setOption(option)

    const handleResize = () => {
      Areachart.resize()
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
      Areachart.dispose()
    }
  }, [lastBackups])

  if (!lastBackups.length) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '240px',
        }}
      >
        No backup data available
      </Box>
    )
  }

  return (
    <Box
      ref={areachartRef}
      sx={{
        position: 'relative',
        width: '100%',
        height: '240px',
      }}
    />
  )
}

export default React.memo(AreaChart)
