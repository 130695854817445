import { SortConfig } from 'ui-v2/src/components/ui/data-table/base-table'
import { EFS_SortBy } from 'ui-v2/src/lib/grpc'
import { EfsTableRow } from 'ui-v2/src/lib/models/assets'

const columnToProtoFieldMap: Partial<
  Record<keyof EfsTableRow, EFS_SortBy.SortField.Field>
> = {
  awsId: EFS_SortBy.SortField.Field.FIELD_AWS_ID,
  awsAccountId: EFS_SortBy.SortField.Field.FIELD_AWS_ACCOUNT_ID,
  awsRegion: EFS_SortBy.SortField.Field.FIELD_AWS_REGION,
  state: EFS_SortBy.SortField.Field.FIELD_STATE,
  backupPolicies: EFS_SortBy.SortField.Field.FIELD_BACKUP_POLICIES,
}

export interface ProtoSortField {
  field: EFS_SortBy.SortField.Field
  ascending: boolean
}

export interface ProtoSortBy {
  sortBy: Array<ProtoSortField>
}

export const convertEfsSortConfigsToProto = (
  sortConfigs: Array<SortConfig>
): ProtoSortBy => {
  return {
    sortBy: sortConfigs
      .map((config) => {
        const protoField =
          columnToProtoFieldMap[config.key as keyof EfsTableRow]
        if (protoField === undefined) {
          return null
        }
        return {
          field: protoField,
          ascending: config.direction === 'asc',
        }
      })
      .filter(
        (field): field is ProtoSortField =>
          field !== null &&
          typeof field === 'object' &&
          'field' in field &&
          'ascending' in field
      ),
  }
}
