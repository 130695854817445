/* eslint-disable import/no-extraneous-dependencies */
import {
  EntropyDetectionPromiseClient,
  GetFiltersForListAwsAssetSumariesRequest,
  ListAnomaliesRequest,
} from '../grpc'
import { ListAnomaliesFilter } from '../models/anomalies'
import { ClientConfig } from '../models/client'
import { AnomalyTransformer } from '../transformers/entropy-detection'
import { GrpcClient } from './grpc'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import {
  EBSTransformer,
  EC2Transformer,
  EFSTransformer,
  S3BucketTransformer,
} from '../transformers/assets'
import { GetFiltersForListAwsAssetSummariesFilter } from '../models/anomalies/anomaly'

export class EntropyDetectionClient extends GrpcClient<EntropyDetectionPromiseClient> {
  #entropyDetectionClient: EntropyDetectionPromiseClient

  #token: string

  constructor({ hostName = '', token }: ClientConfig) {
    super()
    this.#entropyDetectionClient = this.getClient(hostName)
    this.#token = token
  }

  protected innerClientTypeId(): string {
    return 'EntropyDetectionClient'
  }

  protected initClient(hostName = ''): EntropyDetectionPromiseClient {
    return new EntropyDetectionPromiseClient(hostName, null, null)
  }

  async listAnomalies({
    startAt,
    endAt,
    assetIdsList,
    pageSize = 100,
  }: ListAnomaliesFilter) {
    const request = new ListAnomaliesRequest()
    const initial = new ListAnomaliesRequest.Initial()
    const filters = new ListAnomaliesRequest.Initial.Filters()

    filters.setStartAt(this.#setTimestamp(startAt))

    filters.setEndAt(this.#setTimestamp(endAt))

    if (assetIdsList && assetIdsList.length) {
      filters.setAssetIdsList(assetIdsList)
    }

    initial.setFilters(filters)
    initial.setPageSize(pageSize)
    request.setInitial(initial)

    const response = (
      await this.callGrpcService(
        () =>
          this.#entropyDetectionClient.listAnomalies(
            request,
            this.metadata(this.#token)
          ),
        {
          requestName: 'EntropyDetectionPromiseClient/listAnomalies',
        }
      )
    ).toObject()

    return {
      anomaliesList: response.anomaliesList.map((anomaly) =>
        new AnomalyTransformer(anomaly).transform()
      ),
      pageToken: response.pagination?.nextPageToken,
    }
  }

  async getFiltersForListAwsAssetSummaries({
    startAt,
    endAt,
  }: GetFiltersForListAwsAssetSummariesFilter) {
    const request = new GetFiltersForListAwsAssetSumariesRequest()

    request.setStartAt(this.#setTimestamp(startAt))
    request.setEndAt(this.#setTimestamp(endAt))

    const response = await this.callGrpcService(
      () =>
        this.#entropyDetectionClient.getFiltersForListAwsAssetSummaries(
          request,
          this.metadata(this.#token)
        ),
      {
        requestName:
          'EntropyDetectionPromiseClient/getFiltersForListAwsAssetSummaries',
      }
    )

    return response.getAssetsList().reduce(
      (
        assetsMap: {
          ec2Instances: Array<any>
          ebsVolumes: Array<any>
          s3Buckets: Array<any>
          efsFilesystems: Array<any>
        },
        currentAsset
      ) => {
        if (!assetsMap.ec2Instances) {
          assetsMap.ec2Instances = []
          assetsMap.ebsVolumes = []
          assetsMap.s3Buckets = []
          assetsMap.efsFilesystems = []
        }

        const ebsVolume = currentAsset.getEbsVolume()
        if (ebsVolume) {
          const ebs = new EBSTransformer(ebsVolume.toObject()).transform()
          assetsMap.ebsVolumes.push(ebs)
        }

        const ec2Instance = currentAsset.getEc2Instance()
        if (ec2Instance) {
          const ec2 = new EC2Transformer(ec2Instance.toObject()).transform()
          assetsMap.ec2Instances.push(ec2)
        }

        const s3Bucket = currentAsset.getS3Bucket()
        if (s3Bucket) {
          const s3 = new S3BucketTransformer(s3Bucket.toObject()).transform()
          assetsMap.s3Buckets.push(s3)
        }

        const efsFilesystem = currentAsset.getEfsFilesystem()
        if (efsFilesystem) {
          const efs = new EFSTransformer(efsFilesystem.toObject()).transform()
          assetsMap.efsFilesystems.push(efs)
        }

        return assetsMap
      },
      {
        ec2Instances: [],
        ebsVolumes: [],
        s3Buckets: [],
        efsFilesystems: [],
      }
    )
  }

  #setTimestamp(seconds: number): Timestamp {
    const timestamp = new Timestamp()
    timestamp.setSeconds(seconds)
    return timestamp
  }
}
