/* eslint-disable import/no-extraneous-dependencies */
import { GenericHostInventoryOutput } from 'blues-corejs/dist/use_cases/list_generic_hosts_inventory/types'
import StatusChip from 'ui-v2/src/components/ui/data-display/chip'
import Link from 'ui-v2/src/components/ui/data-display/link'
import { Column } from 'ui-v2/src/components/ui/data-table/base-table'
import { formatDateToUtc } from 'ui-v2/src/lib/helpers/time.helper'

export const getInventoryHostColumns = (): Array<
  Column<GenericHostInventoryOutput>
> => [
  {
    key: 'isInfected',
    header: 'Health',
    render: (row) => <StatusChip type={row.isInfected ? 'error' : 'success'} />,
    width: 80,
    align: 'center',
  },
  {
    key: 'genericHostName',
    header: 'Host Name',
    render: (row) => (
      <Link to={`/${row.uuid}`} preservePath preserveSearchParams>
        {row.genericHostName}
      </Link>
    ),
  },
  {
    key: 'lastScan',
    header: 'Last Scan',
    render: (row) => formatDateToUtc(row.lastScan),
  },
  {
    key: 'threats',
    header: 'State',
  },
]
