/* eslint-disable import/no-extraneous-dependencies */
import { Backup, EFS, Threat } from 'blues-corejs/dist'
import { Scan } from 'ui-v2/src/lib/models/scans'
import { AssetsEfsBase } from './efs-base'
import { AssetsEfsAttrs, EfsDrawer } from 'ui-v2/src/lib/models/assets'

export class AssetsEfsDrawerData extends AssetsEfsBase {
  protected readonly filesystems: Array<EFS>

  protected readonly scans: Array<Scan>

  protected readonly lastBackups: Map<string, Backup>

  protected readonly threats: Array<Threat>

  protected readonly filesystemsWithScans: Array<{
    filesystem: EFS
    scans: Array<Scan>
  }>

  constructor(parameters: AssetsEfsAttrs) {
    super()
    this.filesystems = parameters.filesystems
    this.scans = parameters.scans
    this.lastBackups = parameters.lastBackups
    this.threats = parameters.threats
    this.filesystemsWithScans = this.getFilesystemsWithScans()
  }

  getEfsDrawerData() {
    const filesystem = this.filesystems[0]
    if (!filesystem) {
      return undefined
    }
    return new EfsDrawer({
      id: filesystem.id,
      name: filesystem.name,
      awsId: filesystem.awsId,
      awsAccountId: filesystem.awsAccountId,
      awsRegion: filesystem.awsRegion,
      lastBackup: this.getLastBackup(filesystem),
      lastScan: this.getLastScanForFilesystem(filesystem),
      isUnhealthy: this.isUnhealthy(filesystem),
      state: AssetsEfsBase.convertEfsState(filesystem.state),
    })
  }
}
