import { FC, ReactElement, ReactNode, useId, useMemo } from 'react'
import { CancelOutlined } from '@mui/icons-material'
import {
  Checkbox,
  FormControl,
  InputBaseProps,
  InputLabel,
  InputLabelProps,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  SelectProps,
  styled,
  SxProps,
  Theme,
  Typography,
  Button,
  Box,
} from '@mui/material'

interface FilterMultiSelectProps
  extends Omit<
    SelectProps<Array<MultiSelectOption['value']>>,
    'onChange' | 'value' | 'renderValue'
  > {
  options: Array<MultiSelectOption>
  value: Array<MultiSelectOption['value']>
  size?: InputBaseProps['size'] & InputLabelProps['size']
  label?: string | ReactElement
  width?: number | string
  maxWidth?: number | string
  disabled?: boolean
  required?: boolean
  error?: boolean
  helperText?: string
  formControlSx?: SxProps<Theme>
  onChange?: (selectedValues: Array<MultiSelectOption['value']>) => void
  renderValue?: (value: Array<MultiSelectOption['value']>) => ReactNode
  getOptionLabel?: (option: MultiSelectOption) => string
}

export interface MultiSelectOption {
  label: string
  value: string
}

const StyledSelect = styled(Select<Array<MultiSelectOption['value']>>)(
  ({ theme }) => ({
    color: theme.palette.text.muted,
    backgroundColor: theme.palette.background.paper,

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.background.paper,
    },

    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      // borderColor: theme.palette.primary.main,
      borderColor: 'red',
    },

    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.border.main,
    },

    '&:hover .Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
    '& .MuiSelect-select': {
      paddingRight: '48px !important',
    },
  })
)

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  padding: 0,
  backgroundColor: theme.palette.background.paper,
  transition: 'all 0.2s ease-in-out',

  '&.Mui-selected': {
    backgroundColor: theme.palette.background.secondary,

    '&:hover': {
      backgroundColor: theme.palette.background.hover,
    },

    '& .MuiCheckbox-root': {
      color: theme.palette.primary.main,
    },

    '& .MuiListItemText-primary': {
      color: theme.palette.text.primary,
      fontWeight: 500,
    },
  },

  '&:hover': {
    backgroundColor: theme.palette.background.hover,

    '& .MuiCheckbox-root': {
      backgroundColor: 'transparent',
    },

    '& .MuiListItemText-primary': {
      color: theme.palette.text.primary,
    },
  },

  '&:active': {
    backgroundColor: theme.palette.background.secondary,

    '& .MuiCheckbox-root': {
      backgroundColor: 'transparent',
    },
  },
}))

const ClearButton = styled(Button)({
  padding: '6px',
  zIndex: 10,
  backgroundColor: 'transparent',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  textTransform: 'none',
})

const ITEM_HEIGHT = 42
const ITEM_PADDING_TOP = 8

const FilterMultiSelect: FC<FilterMultiSelectProps> = ({
  options,
  value,
  label = 'Select',
  size = 'xs',
  width,
  maxWidth = 300,
  disabled = false,
  required = false,
  error = false,
  helperText,
  formControlSx,
  onChange,
  renderValue,
  getOptionLabel = (option: MultiSelectOption) => option.label,
  ...selectProps
}) => {
  const id = useId()

  const handleChange = (
    event: SelectChangeEvent<Array<MultiSelectOption['value']>>
  ) => {
    const {
      target: { value: val },
    } = event

    onChange?.(val as Array<MultiSelectOption['value']>)
  }

  const handleClear = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    onChange?.([])
  }

  const defaultRenderValue = (
    selectedValues: Array<MultiSelectOption['value']>
  ) => {
    const selectedOptions = selectedValues
      .map((val) => options.find((option) => option.value === val))
      .filter((item) => item !== undefined)
    // @ts-ignore
    return selectedOptions.map(getOptionLabel).join(', ')
  }

  const renderedOptions = useMemo(() => {
    if (options.length) {
      return [
        <StyledMenuItem key="clear-button" disabled={!value.length}>
          <ClearButton
            onClick={handleClear}
            size="small"
            disabled={!value.length}
            aria-label="Clear selection"
          >
            <Typography fontSize={14} paddingLeft={'8px'}>
              Clear
            </Typography>
            <Box
              pr={1}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyItems: 'center',
              }}
            >
              <CancelOutlined fontSize="small" />
            </Box>
          </ClearButton>
        </StyledMenuItem>,
        ...options.map((option) => (
          <StyledMenuItem key={option.value} value={option.value}>
            <Checkbox
              checked={value.includes(option.value)}
              sx={(theme) => ({
                color: theme.palette.grey[700],
              })}
            />
            <ListItemText
              primary={getOptionLabel(option)}
              slotProps={{
                primary: {
                  fontSize: '0.875rem',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  maxWidth,
                },
              }}
            />
          </StyledMenuItem>
        )),
      ]
    } else {
      return (
        <StyledMenuItem disabled>
          <ListItemText
            primary={'No options available'}
            slotProps={{
              primary: {
                fontSize: '0.875rem',
                textAlign: 'center',
              },
            }}
          />
        </StyledMenuItem>
      )
    }
  }, [options, value, getOptionLabel])

  return (
    <FormControl
      sx={{
        width,
        ...formControlSx,
      }}
      disabled={disabled}
      required={required}
      error={error}
      fullWidth={!width}
    >
      <InputLabel size={size} id={`${id}-label`} htmlFor={`${id}-select`}>
        {label}
      </InputLabel>
      <StyledSelect
        labelId={`${id}-label`}
        id={`${id}-select`}
        multiple
        value={value}
        onChange={handleChange}
        input={<OutlinedInput label={label} sx={{ p: 0 }} />}
        renderValue={renderValue ?? defaultRenderValue}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          PaperProps: {
            style: {
              maxWidth,
              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              minWidth: width,
            },
            sx: (theme) => ({
              backgroundColor: theme.palette.background.paper,
            }),
          },
          MenuListProps: {
            sx: (theme) => ({
              backgroundColor: theme.palette.background.paper,
            }),
          },
        }}
        size={size}
        {...selectProps}
      >
        {renderedOptions}
      </StyledSelect>
      {helperText && (
        <Typography variant="body2" color={error ? 'error' : 'text.secondary'}>
          {helperText}
        </Typography>
      )}
    </FormControl>
  )
}

export default FilterMultiSelect
