/* eslint-disable import/no-extraneous-dependencies */
import { Typography } from '@mui/material'
import AreaChart from '../dashboard-charts/area-chart'
import GradientCard from 'ui-v2/src/components/ui/cards/gradient'
import { useAssetsLiveQuery } from 'ui-v2/src/hooks/queries/assets'
import { useMemo } from 'react'
import { BackupType } from 'ui-v2/src/lib/models/assets/live'

export const RecoveryAssurance = () => {
  const { data: assetsLiveData } = useAssetsLiveQuery()

  const lastBackups = useMemo(() => {
    if (!assetsLiveData) {
      return []
    }

    return Array.from(assetsLiveData.lastBackups.backups).reduce<
      Array<BackupType>
    >((backupsAcc, [, backup]) => {
      return [...backupsAcc, backup]
    }, [])
  }, [assetsLiveData])

  return (
    <GradientCard>
      <Typography fontSize={16} fontWeight={700} mb={1} color="text.secondary">
        Recovery Assurance
      </Typography>
      <AreaChart lastBackups={lastBackups} />
    </GradientCard>
  )
}

export default RecoveryAssurance
