/* eslint-disable import/no-extraneous-dependencies */
import { DataHelper } from '@lib/helpers'
import { ListEfsInventory } from 'blues-corejs/dist'
import { listEfsInventoryPossibleFilters } from 'blues-corejs/dist/use_cases/list_efs_inventory_possible_filters'
import React, { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useInventoryTablesFilters } from 'ui-v2/src/hooks/filters/inventory-tables'
import { useAssetsLiveQuery } from 'ui-v2/src/hooks/queries/assets'
import {
  INVENTORY_TABLES_FILTERS_MAP,
  INVENTORY_VIEW_PARAM,
  INVENTORY_VIEW_PARAM_VALUES,
  SEARCH_PARAM,
} from 'ui-v2/src/lib/constants/assets.constant'
import { initialPrioritySortForEFSTable } from './priority-sort'
import { getInventoryEfsColumns } from './column-config'
import { Stack } from '@mui/material'
import SearchBar from 'ui-v2/src/components/ui/inputs/search-bar'
import InventoryTablesFilters from '../filters'
import ClientTable from 'ui-v2/src/components/ui/data-table/client-pagination-table'

const EfsInventory = () => {
  const filters = useInventoryTablesFilters()

  const [searchParams] = useSearchParams()
  const search = searchParams.get(SEARCH_PARAM)
  const view = searchParams.get(INVENTORY_VIEW_PARAM)

  const { data: assetsLiveData, isLoading } = useAssetsLiveQuery()

  const { possibleEfsFilters } = useMemo(() => {
    if (!assetsLiveData) {
      return { possibleEfsFilters: {} }
    }

    return new listEfsInventoryPossibleFilters({
      filesystems: assetsLiveData.efs,
    }).execute()
  }, [assetsLiveData])

  const scans = useMemo(() => {
    if (!assetsLiveData) {
      return []
    }

    return assetsLiveData.scans.flatMap(
      (scan) => scan.ransomwareScan ?? scan.malwareScan ?? []
    )
  }, [assetsLiveData])

  const efs = useMemo(() => {
    if (!assetsLiveData) {
      return []
    }

    const { efs: inventoryEfs } = new ListEfsInventory({
      filesystems: assetsLiveData.efs,
      threats: assetsLiveData.threats,
      scans,
    }).execute(DataHelper.getDataForDashboardFilter(filters, search ?? ''))

    if (view === INVENTORY_VIEW_PARAM_VALUES.CLEAN) {
      return inventoryEfs.filter((filesystem) => !filesystem.isInfected)
    }
    return inventoryEfs
  }, [assetsLiveData, JSON.stringify(filters), search, view, scans])

  const sortedEfs = useMemo(() => {
    return initialPrioritySortForEFSTable(efs)
  }, [efs])

  const columns = useMemo(getInventoryEfsColumns, [])

  return (
    <Stack gap={1}>
      <Stack direction="row" gap={1} alignItems={'center'}>
        <SearchBar />
      </Stack>

      <InventoryTablesFilters
        filters={[
          INVENTORY_TABLES_FILTERS_MAP.ACCOUNT_ID,
          INVENTORY_TABLES_FILTERS_MAP.REGION,
          INVENTORY_TABLES_FILTERS_MAP.POLICY,
        ]}
        filterValues={{
          [INVENTORY_TABLES_FILTERS_MAP.REGION]: possibleEfsFilters.regions,
          [INVENTORY_TABLES_FILTERS_MAP.ACCOUNT_ID]:
            possibleEfsFilters.accountIds,
          [INVENTORY_TABLES_FILTERS_MAP.POLICY]:
            possibleEfsFilters.covered?.map((option) => option.toString()),
        }}
      />
      <ClientTable data={sortedEfs} columns={columns} isLoading={isLoading} />
    </Stack>
  )
}

export default EfsInventory
