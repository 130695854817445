/* eslint-disable import/no-extraneous-dependencies */
import {
  ListInventoryS3Buckets,
  listS3BucketInventoryPossibleFilters,
} from 'blues-corejs/dist'
import React, { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useInventoryTablesFilters } from 'ui-v2/src/hooks/filters/inventory-tables'
import { useAssetsLiveQuery } from 'ui-v2/src/hooks/queries/assets'
import {
  INVENTORY_TABLES_FILTERS_MAP,
  INVENTORY_VIEW_PARAM,
  INVENTORY_VIEW_PARAM_VALUES,
  SEARCH_PARAM,
} from 'ui-v2/src/lib/constants/assets.constant'
import { Stack } from '@mui/material'
import SearchBar from 'ui-v2/src/components/ui/inputs/search-bar'
import InventoryTablesFilters from '../filters'
import ClientTable from 'ui-v2/src/components/ui/data-table/client-pagination-table'
import { getEncryptionActivityS3Columns } from './column-config'
import DataHelper from 'ui-v2/src/lib/helpers/data.helper'
import { initialPrioritySortForS3Table } from './priority-sort'
import { useGetFiltersForListAwsAssetSummariesQuery } from 'ui-v2/src/hooks/queries/entropy-detection'
import dayjs from 'dayjs'

const S3EncryptionActivity = () => {
  const filters = useInventoryTablesFilters()

  const [searchParams] = useSearchParams()
  const search = searchParams.get(SEARCH_PARAM)
  const view = searchParams.get(INVENTORY_VIEW_PARAM)

  const { data: getFiltersForListAwsAssetSummariesData } =
    useGetFiltersForListAwsAssetSummariesQuery({
      startAt: Math.floor(
        dayjs().startOf('day').subtract(30, 'day').valueOf() / 1000
      ),

      endAt: Math.floor(dayjs().endOf('day').valueOf() / 1000),
    })

  const { data: assetsLiveData, isLoading } = useAssetsLiveQuery()

  const { possibleS3BucketFilters } = useMemo(() => {
    if (!assetsLiveData) {
      return { possibleS3BucketFilters: {} }
    }

    return new listS3BucketInventoryPossibleFilters({
      s3Buckets: assetsLiveData.s3Buckets,
    }).execute()
  }, [assetsLiveData])

  const scans = useMemo(() => {
    if (!assetsLiveData) {
      return []
    }

    return assetsLiveData.scans.flatMap((scan) => scan.malwareScan ?? [])
  }, [assetsLiveData])

  const s3Buckets = useMemo(() => {
    if (!assetsLiveData || !getFiltersForListAwsAssetSummariesData) {
      return []
    }

    const { s3Buckets: inventoryS3Buckets } = new ListInventoryS3Buckets({
      S3Buckets: getFiltersForListAwsAssetSummariesData.s3Buckets,
      threats: assetsLiveData.threats,
      lastScans: scans,
    }).execute(DataHelper.getDataForDashboardFilter(filters, search ?? ''))

    if (view === INVENTORY_VIEW_PARAM_VALUES.CLEAN) {
      return inventoryS3Buckets.filter((s3Bucket) => !s3Bucket.isInfected)
    }
    return inventoryS3Buckets
  }, [
    assetsLiveData,
    getFiltersForListAwsAssetSummariesData,
    JSON.stringify(filters),
    search,
    view,
    scans,
  ])

  const sortedS3Buckets = useMemo(() => {
    return initialPrioritySortForS3Table(s3Buckets)
  }, [s3Buckets])

  const columns = useMemo(getEncryptionActivityS3Columns, [])

  return (
    <Stack gap={1}>
      <Stack direction="row" gap={1} alignItems={'center'}>
        <SearchBar />
      </Stack>

      <InventoryTablesFilters
        filters={[
          INVENTORY_TABLES_FILTERS_MAP.ACCOUNT_ID,
          INVENTORY_TABLES_FILTERS_MAP.REGION,
          INVENTORY_TABLES_FILTERS_MAP.POLICY,
        ]}
        filterValues={{
          [INVENTORY_TABLES_FILTERS_MAP.REGION]:
            possibleS3BucketFilters.regions,
          [INVENTORY_TABLES_FILTERS_MAP.ACCOUNT_ID]:
            possibleS3BucketFilters.accountIds,
          [INVENTORY_TABLES_FILTERS_MAP.POLICY]:
            possibleS3BucketFilters.covered?.map((option) => option.toString()),
        }}
      />
      <ClientTable
        data={sortedS3Buckets}
        columns={columns}
        isLoading={isLoading}
      />
    </Stack>
  )
}

export default S3EncryptionActivity
