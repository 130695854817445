import RootLayout from 'ui-v2/src/components/layout/root-layout'
import Assets from 'ui-v2/src/views/assets'
import Dashboard from 'ui-v2/src/views/dashboard/dashboard'
import {
  Navigate,
  Outlet,
  RouterProvider as ReactRouterProvider,
} from 'react-router-dom'
import { createBrowserRouter } from 'react-router-dom'
import Monitor from 'ui-v2/src/views/monitor'
import Reports from 'ui-v2/src/views/reports'
import Settings from 'ui-v2/src/views/settings'
import AccessDenied from 'ui-v2/src/views/tenant/access-denied'
import Unauthenticated from 'ui-v2/src/views/tenant/unauthenticated'
import Account from 'ui-v2/src/views/account'
import {
  ACTIVE_THREATS_ROUTE_SEGMENTS,
  ASSETS_DRAWER_ROUTE_SEGMENTS,
  BASE_ROUTE_SEGMENTS,
  DASHBOARD_ROUTE_SEGMENTS,
  SETTINGS_ROUTE_SEGMENTS,
  TENANT_ROUTE_SEGMENTS,
} from '../lib/constants/route-segments.constant'
import AssetsThreats from '../views/dashboard/assets-threats'
import ActiveThreats from '../views/dashboard/active-threats'
import RedirectToUpdatedAssetPath from '../components/navigation/redirect-to-assets-path'
import RedirectToUpdatedAssetDetailsPath from '../components/navigation/redirect-to-asset-path'
import AssetsVulnerabilities from '../views/dashboard/assets-vulnerabilities'
import Auth0ProviderV2 from './auth0'
import Onboarding from '../views/onboarding'
import NotFoundPage from '../components/common/not-found'
import InventoryTables from '../views/dashboard/inventory-tables'
import EncryptionActivity from '../views/dashboard/encryption-activity'

export const RouterProvider = () => {
  const router = createBrowserRouter([
    {
      path: BASE_ROUTE_SEGMENTS.TENANT,
      element: (
        <Auth0ProviderV2>
          <Outlet />
        </Auth0ProviderV2>
      ),
      children: [
        {
          path: TENANT_ROUTE_SEGMENTS.ACCESS_DENIED,
          element: <AccessDenied />,
        },
        {
          path: TENANT_ROUTE_SEGMENTS.UNAUTHENTICATED,
          element: <Unauthenticated />,
        },
      ],
    },
    {
      path: '/',
      element: (
        <Auth0ProviderV2>
          <RootLayout />
        </Auth0ProviderV2>
      ),
      children: [
        {
          index: true,
          element: (
            <Navigate to={`/${BASE_ROUTE_SEGMENTS.DASHBOARD}`} replace />
          ),
        },
        {
          path: BASE_ROUTE_SEGMENTS.DASHBOARD,
          children: [
            {
              path: '',
              element: <Dashboard />,
            },
            {
              path: `${DASHBOARD_ROUTE_SEGMENTS.ASSET_THREATS}/:assetId?`,
              element: <AssetsThreats />,
              children: [
                {
                  path: ASSETS_DRAWER_ROUTE_SEGMENTS.RESTORE_LAST_CLEAN_BACKUP,
                  element: <AssetsThreats />,
                },
              ],
            },
            {
              path: `${DASHBOARD_ROUTE_SEGMENTS.INVENTORY_TABLES}/:assetId?`,
              element: <InventoryTables />,
              children: [
                {
                  path: ASSETS_DRAWER_ROUTE_SEGMENTS.RESTORE_LAST_CLEAN_BACKUP,
                  element: <InventoryTables />,
                },
              ],
            },
            {
              path: `${DASHBOARD_ROUTE_SEGMENTS.ENCRYPTION_ACTIVITY}/:assetId?`,
              element: <EncryptionActivity />,
              children: [
                {
                  path: ASSETS_DRAWER_ROUTE_SEGMENTS.RESTORE_LAST_CLEAN_BACKUP,
                  element: <EncryptionActivity />,
                },
              ],
            },
            {
              path: `${DASHBOARD_ROUTE_SEGMENTS.ACTIVE_THREATS}/${ACTIVE_THREATS_ROUTE_SEGMENTS.AWS}/:assetId?`,
              element: <ActiveThreats />,
              children: [
                {
                  path: ASSETS_DRAWER_ROUTE_SEGMENTS.RESTORE_LAST_CLEAN_BACKUP,
                  element: <ActiveThreats />,
                },
              ],
            },
            {
              path: `${DASHBOARD_ROUTE_SEGMENTS.ASSET_VULNERABILITIES}/:assetId?`,
              element: <AssetsVulnerabilities />,
              children: [
                {
                  path: ASSETS_DRAWER_ROUTE_SEGMENTS.RESTORE_LAST_CLEAN_BACKUP,
                  element: <AssetsVulnerabilities />,
                },
              ],
            },
          ],
        },
        {
          path: `${BASE_ROUTE_SEGMENTS.ASSETS}/:tab?/:assetId?`,
          element: <Assets />,
          children: [
            {
              path: ASSETS_DRAWER_ROUTE_SEGMENTS.RESTORE_LAST_CLEAN_BACKUP,
              element: <Assets />,
            },
          ],
        },
        {
          path: `${BASE_ROUTE_SEGMENTS.MONITOR}/:id?`,
          element: <Monitor />,
        },
        {
          path: `${BASE_ROUTE_SEGMENTS.REPORTS}/:tab?/:drawer?/:reportFormSlug?/:kindSlug?/:reportId?`,
          element: <Reports />,
        },
        {
          path: BASE_ROUTE_SEGMENTS.SETTINGS,
          children: [
            {
              path: '',
              element: (
                <Navigate to={SETTINGS_ROUTE_SEGMENTS.GENERAL} replace />
              ),
            },
            {
              path: `${SETTINGS_ROUTE_SEGMENTS.GENERAL}/:subTab?/:action?/:id?`,
              element: <Settings tab={SETTINGS_ROUTE_SEGMENTS.GENERAL} />,
            },
            {
              path: `${SETTINGS_ROUTE_SEGMENTS.USERS_AND_ACCESS}/:subTab?/:action?`,
              element: (
                <Settings tab={SETTINGS_ROUTE_SEGMENTS.USERS_AND_ACCESS} />
              ),
            },
            {
              path: `${SETTINGS_ROUTE_SEGMENTS.WEBHOOKS}/:action?/:id?`,
              element: <Settings tab={SETTINGS_ROUTE_SEGMENTS.WEBHOOKS} />,
            },
            {
              path: `${SETTINGS_ROUTE_SEGMENTS.SOURCES}/:action?/:id?`,
              element: <Settings tab={SETTINGS_ROUTE_SEGMENTS.SOURCES} />,
            },
            {
              path: `${SETTINGS_ROUTE_SEGMENTS.POLICIES}/:subTab?/:action?/:id?`,
              element: <Settings tab={SETTINGS_ROUTE_SEGMENTS.POLICIES} />,
            },
          ],
        },
        {
          path: BASE_ROUTE_SEGMENTS.ONBOARDING,
          element: <Onboarding />,
        },
        {
          path: `${BASE_ROUTE_SEGMENTS.DASHBOARD}/${DASHBOARD_ROUTE_SEGMENTS.INVENTORY_TABLES}`,
          element: <RedirectToUpdatedAssetPath />,
        },
        {
          path: `${BASE_ROUTE_SEGMENTS.ASSET}/:assetId`,
          element: <RedirectToUpdatedAssetDetailsPath />,
        },
        {
          path: `${BASE_ROUTE_SEGMENTS.JOBS}/*`,
          element: <Navigate to={`/${BASE_ROUTE_SEGMENTS.MONITOR}`} replace />,
        },
        {
          path: BASE_ROUTE_SEGMENTS.ACCOUNTS_SETTINGS,
          element: <Account />,
        },
        {
          path: BASE_ROUTE_SEGMENTS.SOURCES,
          element: (
            <Navigate
              to={`/${BASE_ROUTE_SEGMENTS.SETTINGS}/${SETTINGS_ROUTE_SEGMENTS.SOURCES}`}
              replace
            />
          ),
        },
        {
          path: BASE_ROUTE_SEGMENTS.POLICIES,
          element: (
            <Navigate
              to={`/${BASE_ROUTE_SEGMENTS.SETTINGS}/${SETTINGS_ROUTE_SEGMENTS.POLICIES}`}
              replace
            />
          ),
        },

        {
          path: '*',
          element: <NotFoundPage />,
        },
      ],
    },
  ])

  return <ReactRouterProvider router={router} />
}
