/* eslint-disable import/no-extraneous-dependencies */
import { AssetsS3Attrs, S3TableRow } from 'ui-v2/src/lib/models/assets'
import { Scan } from 'ui-v2/src/lib/models/scans'
import { Backup, S3Bucket, Threat } from 'blues-corejs/dist'
import { AssetsS3Base } from './s3-base'

export class AssetsS3TableData extends AssetsS3Base {
  protected readonly s3Buckets: Array<S3Bucket>

  protected readonly scans: Array<Scan>

  protected readonly lastBackups: Map<string, Backup>

  protected readonly threats: Array<Threat>

  protected readonly bucketsWithScans: Array<{
    bucket: S3Bucket
    scans: Array<Scan>
  }>

  constructor(parameters: AssetsS3Attrs) {
    super()
    this.s3Buckets = parameters.s3Buckets
    this.scans = parameters.scans
    this.lastBackups = parameters.lastBackups
    this.threats = parameters.threats
    this.bucketsWithScans = this.getBucketsWithScans()
  }

  getS3TableData(): Array<S3TableRow> {
    return this.s3Buckets.map(
      (bucket) =>
        new S3TableRow({
          id: bucket.id,
          name: bucket.name,
          awsId: bucket.awsId,
          awsRegion: bucket.awsRegion,
          awsAccountId: bucket.awsAccountId,
          lastBackupDate: this.getLastBackup(bucket)?.timestamp,
          lastScanDate: this.getLastScanForBucket(bucket)?.completedAt,
          backupPolicies: bucket.backupPolicies,
          isUnhealthy: this.isUnhealthyBucket(bucket),
          state: this.convertBucketState(bucket.state),
        })
    )
  }
}
