import { useNavigate, useSearchParams } from 'react-router-dom'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { SyntheticEvent, useEffect } from 'react'
import {
  BASE_ROUTE_SEGMENTS,
  DASHBOARD_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'
import AssetsVulnerabilitiesInstances from './ec2Instances'
import AssetsVulnerabilitiesVolumes from './ebsVolumes'
import AssetsVulnerabilitiesSnapshots from './ebsSnapshots'
import AssetsDetailsDrawer from 'ui-v2/src/components/drawers/asset-details'
import AssetRestoreCleanBackup from 'ui-v2/src/components/drawers/restore-clean-backup'

interface TabPanelProps {
  children?: React.ReactNode
  value: string
  slug: string
}

export enum AssetVulnerabilitiesTabs {
  INSTANCES = '0',
  VOLUMES = '1',
  SNAPSHOTS = '2',
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, slug, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== slug}
      id={`tabpanel-${slug}`}
      aria-labelledby={`tab-${slug}`}
      {...other}
    >
      {value === slug && <Box sx={{ py: 1 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(slug: string) {
  return {
    id: `tab-${slug}`,
    'aria-controls': `tabpanel-${slug}`,
  }
}

const TAB_MAP: Record<
  string,
  {
    label: string
    component: JSX.Element
    slug: AssetVulnerabilitiesTabs
  }
> = {
  ec2Instances: {
    label: 'Instances',
    component: <AssetsVulnerabilitiesInstances />,
    slug: AssetVulnerabilitiesTabs.INSTANCES,
  },
  ebsVolumes: {
    label: 'Volumes',
    component: <AssetsVulnerabilitiesVolumes />,
    slug: AssetVulnerabilitiesTabs.VOLUMES,
  },
  ebsSnapshots: {
    label: 'Snapshots',
    component: <AssetsVulnerabilitiesSnapshots />,
    slug: AssetVulnerabilitiesTabs.SNAPSHOTS,
  },
}

export default function AssetsVulnerabilities() {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const tab = searchParams.get('t') as AssetVulnerabilitiesTabs | null
  const defaultTab = AssetVulnerabilitiesTabs.VOLUMES
  const defaultPath = `/${BASE_ROUTE_SEGMENTS.DASHBOARD}/${DASHBOARD_ROUTE_SEGMENTS.ASSET_VULNERABILITIES}?t=${defaultTab}`

  useEffect(() => {
    if (!tab) {
      navigate(defaultPath, { replace: true })
      return
    }

    if (!Object.values(AssetVulnerabilitiesTabs).includes(tab)) {
      navigate(defaultPath, { replace: true })
      return
    }
  }, [tab, navigate, defaultPath])

  if (!tab || !Object.values(AssetVulnerabilitiesTabs).includes(tab)) {
    return null
  }

  const handleChange = (
    _: SyntheticEvent,
    newSlug: AssetVulnerabilitiesTabs
  ) => {
    setSearchParams({ t: newSlug })
  }

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
          }}
        >
          <Tabs
            value={tab}
            onChange={handleChange}
            aria-label="asset tabs"
            sx={(theme) => ({
              backgroundColor: theme.palette.background.paper,
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              paddingLeft: 3,
              paddingRight: 3,
              '& .MuiTab-root.Mui-selected': {
                color: theme.palette.text.primary,
              },
            })}
          >
            {Object.values(TAB_MAP).map((tabItem) => (
              <Tab
                key={tabItem.slug}
                value={tabItem.slug}
                label={tabItem.label}
                sx={{ py: 0 }}
                {...a11yProps(tabItem.slug)}
              />
            ))}
          </Tabs>
        </Box>
        {Object.entries(TAB_MAP).map(([key, value]) => (
          <CustomTabPanel key={key} value={tab} slug={value.slug}>
            {value.component}
          </CustomTabPanel>
        ))}
      </Box>
      <AssetsDetailsDrawer />
      <AssetRestoreCleanBackup />
    </>
  )
}
