import { useMemo } from 'react'
import { Typography } from '@mui/material'
import DoughnutChart from '../dashboard-charts/doughnut-chart'
import GradientCard from 'ui-v2/src/components/ui/cards/gradient'
import { useGetFiltersForListAwsAssetSummariesQuery } from 'ui-v2/src/hooks/queries/entropy-detection'
import dayjs from 'dayjs'
import { useAssetsLiveQuery } from 'ui-v2/src/hooks/queries/assets'
import {
  BASE_ROUTE_SEGMENTS,
  DASHBOARD_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'
import { useNavigate } from 'react-router-dom'

export const EncryptionIntegrity = () => {
  const navigate = useNavigate()

  const { data: getFiltersForListAwsAssetSummariesData } =
    useGetFiltersForListAwsAssetSummariesQuery({
      startAt: Math.floor(
        dayjs().startOf('day').subtract(30, 'day').valueOf() / 1000
      ),

      endAt: Math.floor(dayjs().endOf('day').valueOf() / 1000),
    })

  const { data: assetsLiveData } = useAssetsLiveQuery()

  const chartData = useMemo(() => {
    if (!assetsLiveData || !getFiltersForListAwsAssetSummariesData) {
      return {
        totalCount: 0,
        data: [
          {
            name: 'EBS Volumes 0',
            value: 0,
            itemStyle: { color: '#FF1654' },
          },
          {
            name: 'S3 Buckets 0',
            value: 0,
            itemStyle: { color: '#E28800' },
          },
        ],
      }
    }

    const affectedEbsVolumes =
      getFiltersForListAwsAssetSummariesData.ebsVolumes.length

    const affectedS3Buckets =
      getFiltersForListAwsAssetSummariesData.s3Buckets.length

    const totalCount = affectedEbsVolumes + affectedS3Buckets

    const ebsPercentage =
      Math.round((affectedEbsVolumes / totalCount) * 100) || 0
    const s3Percentage = Math.round((affectedS3Buckets / totalCount) * 100) || 0

    return {
      totalCount,
      data: [
        {
          name: `EBS Volumes  {bold|${affectedEbsVolumes}/${ebsPercentage}%}`,
          value: ebsPercentage,
          itemStyle: { color: '#FF1654' },
        },
        {
          name: `S3 Buckets  {bold|${affectedS3Buckets}/${s3Percentage}%}`,
          value: s3Percentage,
          itemStyle: { color: '#E28800' },
        },
      ],
    }
  }, [assetsLiveData, getFiltersForListAwsAssetSummariesData])

  const onChartClick = (componentIndex: number) => {
    switch (componentIndex) {
      case 0:
        navigate(
          `/${BASE_ROUTE_SEGMENTS.DASHBOARD}/${DASHBOARD_ROUTE_SEGMENTS.ENCRYPTION_ACTIVITY}?t=0`
        )
        break
      case 1:
        navigate(
          `/${BASE_ROUTE_SEGMENTS.DASHBOARD}/${DASHBOARD_ROUTE_SEGMENTS.ENCRYPTION_ACTIVITY}?t=1`
        )
        break
      default:
    }
  }

  return (
    <GradientCard>
      <Typography fontSize={16} fontWeight={700} mb={1} color="text.secondary">
        Encryption Activity
      </Typography>
      <DoughnutChart
        title={'Assets with\nEncryption\nIntegrity'}
        count={chartData.totalCount ?? 0}
        data={chartData.data}
        onClick={onChartClick}
        graphicPosition={{
          top: '45%',
          left: '20%',
        }}
      />
    </GradientCard>
  )
}

export default EncryptionIntegrity
