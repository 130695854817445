import { Ec2TableRow } from 'ui-v2/src/lib/models/assets'
import Link from 'ui-v2/src/components/ui/data-display/link'
import StatusChip from 'ui-v2/src/components/ui/data-display/chip'
import { Column } from 'ui-v2/src/components/ui/data-table/base-table'
import { LangAwsRegionConstant } from 'ui-v2/src/lib/constants/lang.constant'

export const getEc2Columns = (): Array<Column<Ec2TableRow>> => [
  {
    key: 'isUnhealthy',
    header: 'Health',
    render: (row) => (
      <StatusChip type={row.isUnhealthy ? 'error' : 'success'} />
    ),
    width: 80,
    align: 'center',
  },
  {
    key: 'name',
    header: 'Instance',
    render: (row) => (
      <Link to={`/${row.id}`} preservePath preserveSearchParams>
        {row.name}
      </Link>
    ),
  },
  {
    key: 'awsId',
    header: 'Instance ID',
    render: (row) => (
      <Link to={`/${row.id}`} preservePath preserveSearchParams>
        {row.awsId}
      </Link>
    ),
    sortable: true,
  },
  {
    key: 'awsAccountId',
    header: 'Account ID',
    sortable: true,
  },
  {
    key: 'awsRegion',
    header: 'Region',
    render: (row) => {
      const region = LangAwsRegionConstant.get(row.awsRegion)
      return region?.en?.single ?? ''
    },
    sortable: true,
  },
  {
    key: 'state',
    header: 'State',
    sortable: true,
  },
  {
    key: 'backupPolicies',
    header: 'Policy Coverage',
    render: (row) => row.backupPolicies.join(', '),
    wrap: true,
    sortable: true,
  },
]
