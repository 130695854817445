import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import Label from 'ui-v2/src/components/ui/data-display/label'
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerSubHeader,
} from 'ui-v2/src/components/ui/drawer'
import BasicSelect from 'ui-v2/src/components/ui/inputs/basic-select'
import TextField from 'ui-v2/src/components/ui/inputs/text-field'
import {
  SETTINGS_ACTIONS_ROUTE_SEGMENTS,
  SETTINGS_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'
import {
  Box,
  FormControl,
  Grid2,
  Stack,
  Button,
  Divider,
  InputAdornment,
} from '@mui/material'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import {
  useGetWebhookQuery,
  useListEventTypesQuery,
} from 'ui-v2/src/hooks/queries/webhooks'
import {
  authorizationOptions,
  getWebhooksEventType,
  severityOptions,
  versionOptions,
  WEBHOOK_MAP,
} from './data'
import FilterMultiSelect, {
  MultiSelectOption,
} from 'ui-v2/src/components/ui/inputs/multiple-select-filter'
import DeleteIcon from 'ui-v2/src/assets/icons/delete-icon'
import WebhookModel, {
  ApiKey,
  BasicAuth,
  BearerToken,
  EventTypeConstant,
  Secret,
  Severity,
  WebhookAuthentication,
  WebhookEventSubscription,
} from 'ui-v2/src/lib/models/webhooks'
import { useCreateWebhookMutation } from 'ui-v2/src/hooks/queries/webhooks/create-webhook'
import { useUpdateWebhookMutation } from 'ui-v2/src/hooks/queries/webhooks/update-webhook'

type AuthType = 'none' | 'basic_authorization' | 'bearer' | 'api_key'
type VersionType = 'v0'

type AuthenticationType = {
  basic_auth?: {
    login: {
      old_value: string | null
      value: string
    }
    password: {
      old_value: string | null
      value: string
    }
  }
  bearer_token?: {
    token: {
      old_value: string | null
      value: string
    }
  }
  api_key?: {
    key: {
      old_value: string | null
      value: string
    }
    value: string
  }
}

type EventSubscriptionsType = Array<{
  event_type: string
  version: string
  severities: Array<string>
}>

interface WebhookFormData {
  name: string
  endpoint: string
  authentication: AuthenticationType
  description?: string
  event_subscriptions: EventSubscriptionsType
}

interface EventList {
  versionType: string
  severityList: Array<string>
  eventType: string
}

const AddWebhookDrawer = () => {
  const { action, id } = useParams()
  const navigate = useNavigate()

  const [authType, setAuthType] = useState<AuthType>('none')
  const [versionType, setVersionType] = useState<VersionType>('v0')
  const [eventType, setEventType] = useState<string>('account-level-stack')
  const [eventList, setEventList] = useState<Array<EventList>>([])
  const [isAddDisabled, setIsAddDisabled] = useState(true)
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)

  const { data: eventTypesData } = useListEventTypesQuery()
  const { data: webhookData } = useGetWebhookQuery(id as string)
  const { mutate: createWebhookMutate } = useCreateWebhookMutation()
  const { mutate: updateWebhookMutate } = useUpdateWebhookMutation()

  const [searchParams, setSearchParams] = useSearchParams()
  const severityList = searchParams.get(WEBHOOK_MAP.SEVERITY)?.split(',') || []

  const severityLists = useMemo(() => {
    return (
      severityOptions?.map((severityOption) => ({
        label: severityOption.label,
        value: severityOption.label,
      })) || []
    )
  }, [severityOptions])

  const eventTypeList = useMemo(() => {
    if (!eventTypesData || !eventList) {
      return []
    }

    const selectedEventTypes = new Set(
      eventList.map((event) => event.eventType)
    )

    // Filter out event types that are already added in the eventList
    return eventTypesData.eventTypesList
      .filter(
        (eventTypeOption) =>
          !selectedEventTypes.has(getWebhooksEventType(eventTypeOption.name))
      )
      .map((eventTypeOption) => ({
        label: getWebhooksEventType(eventTypeOption.name),
        value: eventTypeOption.name,
      }))
  }, [eventTypesData, eventList])

  const handleSeverityChange = (
    newSeverityList: Array<MultiSelectOption['value']>
  ) => {
    if (newSeverityList.length === 0) {
      setIsAddDisabled(true)
      searchParams.delete(WEBHOOK_MAP.SEVERITY)
    } else {
      searchParams.set(WEBHOOK_MAP.SEVERITY, newSeverityList.join(','))
      setIsAddDisabled(false)
    }
    setSearchParams(new URLSearchParams(searchParams))
  }

  const handleAddEvent = () => {
    setEventList((prevList) => [
      ...prevList,
      {
        versionType,
        severityList,
        eventType: eventType,
      },
    ])
    setEventType('')
    setSearchParams([])
    setIsAddDisabled(true)
  }

  const handleDeleteEvent = (index: number) => {
    const updatedList = eventList.filter((_, i) => i !== index)
    setEventList(updatedList)
  }

  const mapSeverityToEnum = (severity: string): Severity => {
    switch (severity.toLowerCase()) {
      case 'error':
        return Severity.ERROR
      case 'warning':
        return Severity.WARNING
      case 'info':
        return Severity.INFO
      case 'trace':
        return Severity.TRACE
      default:
        return Severity.ERROR
    }
  }

  const stripProtocol = (url: string) => {
    return url.replace(/^(https?:\/\/)/, '')
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm<WebhookFormData>({
    defaultValues: {
      name: '',
      endpoint: '',
      authentication: {
        basic_auth: {
          login: {
            old_value: null,
            value: '',
          },
          password: {
            old_value: null,
            value: '',
          },
        },
        bearer_token: {
          token: {
            old_value: null,
            value: '',
          },
        },
        api_key: {
          key: {
            old_value: null,
            value: '',
          },
          value: '',
        },
      },
      description: '',
      event_subscriptions: [],
    },
  })

  const closeDialog = () => {
    reset()
    eventList.length = 0
    setIsSubmitDisabled(true)
    setAuthType('none')
    navigate(`/settings/${SETTINGS_ROUTE_SEGMENTS.WEBHOOKS}`)
  }

  const onSubmit = (data: WebhookFormData) => {
    // Removing unused auth fields based on selected auth type
    const formData = { ...data }

    switch (authType) {
      case 'none':
        delete formData.authentication.api_key
        delete formData.authentication.basic_auth
        delete formData.authentication.bearer_token
        break
      case 'basic_authorization':
        delete formData.authentication.bearer_token
        delete formData.authentication.api_key
        break
      case 'bearer':
        delete formData.authentication.basic_auth
        delete formData.authentication.api_key
        break
      case 'api_key':
        delete formData.authentication.basic_auth
        delete formData.authentication.bearer_token
        break
    }

    let authentication: BasicAuth | ApiKey | BearerToken | undefined = undefined

    const { authentication: authenticationFormData } = formData

    if (authenticationFormData.api_key) {
      const keySecret = new Secret({
        secret: authenticationFormData.api_key.key.value,
        encrypted: false,
      })
      const valueSecret = new Secret({
        secret: authenticationFormData.api_key.value,
        encrypted: false,
      })
      authentication = new ApiKey(keySecret, valueSecret)
    } else if (authenticationFormData.basic_auth) {
      const loginSecret = new Secret({
        secret: authenticationFormData.basic_auth.login.value,
        encrypted: false,
      })
      const passwordSecret = new Secret({
        secret: authenticationFormData.basic_auth.password.value,
        encrypted: false,
      })
      authentication = new BasicAuth(loginSecret, passwordSecret)
    } else if (authenticationFormData.bearer_token) {
      const tokenSecret = new Secret({
        secret: authenticationFormData.bearer_token.token.value,
        encrypted: false,
      })
      authentication = new BearerToken(tokenSecret)
    }

    const events: Array<WebhookEventSubscription> = eventList.map((item) => {
      const severities = item.severityList
        .map((severity) => mapSeverityToEnum(severity))
        .filter((severity) => severity !== undefined)
      return new WebhookEventSubscription(
        item.eventType,
        item.versionType,
        severities
      )
    })

    if (action === SETTINGS_ACTIONS_ROUTE_SEGMENTS.ADD_WEBHOOK) {
      createWebhookMutate(
        new WebhookModel({
          id: String(Date.now()),
          name: formData.name,
          endpoint: formData.endpoint,
          authentication: new WebhookAuthentication(authentication),
          description: formData.description ?? '',
          event_subscriptions: events,
        })
      )
    } else {
      updateWebhookMutate(
        new WebhookModel({
          id: String(id),
          name: formData.name,
          endpoint: formData.endpoint,
          authentication: new WebhookAuthentication(authentication),
          description: formData.description ?? '',
          event_subscriptions: events,
        })
      )
    }

    closeDialog()
    reset()
  }

  const renderAuthenticationFields = () => {
    switch (authType) {
      case 'basic_authorization':
        return (
          <Grid2 container spacing={2}>
            <Grid2 size={6}>
              <FormControl fullWidth>
                <Label>Login</Label>
                <TextField
                  {...register('authentication.basic_auth.login.value', {
                    required:
                      authType === 'basic_authorization'
                        ? 'Username is required'
                        : false,
                  })}
                  placeholder="Login"
                  fullWidth
                  error={!!errors.authentication?.basic_auth?.login}
                  helperText={errors.authentication?.basic_auth?.login?.message}
                />
              </FormControl>
            </Grid2>
            <Grid2 size={6}>
              <FormControl fullWidth>
                <Label>Password</Label>
                <TextField
                  {...register('authentication.basic_auth.password.value', {
                    required:
                      authType === 'basic_authorization'
                        ? 'Password is required'
                        : false,
                  })}
                  type="password"
                  placeholder="Password"
                  fullWidth
                  error={!!errors.authentication?.basic_auth?.password}
                  helperText={
                    errors.authentication?.basic_auth?.password?.message
                  }
                />
              </FormControl>
            </Grid2>
          </Grid2>
        )

      case 'bearer':
        return (
          <FormControl fullWidth>
            <Label>Bearer Token</Label>
            <TextField
              {...register('authentication.bearer_token.token.value', {
                required:
                  authType === 'bearer' ? 'Bearer token is required' : false,
              })}
              placeholder="Bearer Token"
              fullWidth
              error={!!errors.authentication?.bearer_token?.token}
              helperText={errors.authentication?.bearer_token?.token?.message}
            />
          </FormControl>
        )

      case 'api_key':
        return (
          <Grid2 container spacing={2}>
            <Grid2 size={6}>
              <FormControl fullWidth>
                <Label>Key</Label>
                <TextField
                  {...register('authentication.api_key.key.value', {
                    required:
                      authType === 'api_key'
                        ? 'API key name is required'
                        : false,
                  })}
                  placeholder="API Key"
                  fullWidth
                  error={!!errors.authentication?.api_key?.key}
                  helperText={errors.authentication?.api_key?.key?.message}
                />
              </FormControl>
            </Grid2>
            <Grid2 size={6}>
              <FormControl fullWidth>
                <Label>Value</Label>
                <TextField
                  {...register('authentication.api_key.value', {
                    required:
                      authType === 'api_key' ? 'API key is required' : false,
                  })}
                  placeholder="API Value"
                  fullWidth
                  error={!!errors.authentication?.api_key?.value}
                  helperText={errors.authentication?.api_key?.value?.message}
                />
              </FormControl>
            </Grid2>
          </Grid2>
        )

      default:
        return null
    }
  }

  useEffect(() => {
    if (webhookData) {
      const endpoint = webhookData.getEndpoint()
      const updatedEndpoint = endpoint.replace(/^https?:\/\//, '')

      const webhookAuthentication = webhookData.getAuthentication()
      const fillDefaultAuthDataForm = () => {
        const webhookAuthType = webhookAuthentication.intoInner()
        if (webhookAuthType instanceof BasicAuth) {
          const basic = new BasicAuth(
            new Secret({
              secret: webhookAuthType.getLogin(),
              encrypted: true,
            }),
            new Secret({
              secret: webhookAuthType.getPassword(),
              encrypted: true,
            })
          )

          return {
            login: basic.getLogin(),
            password: basic.getPassword(),
          }
        }
        if (webhookAuthType instanceof ApiKey) {
          const apiKey = new ApiKey(
            new Secret({
              secret: webhookAuthType.getKey(),
              encrypted: true,
            }),
            new Secret({
              secret: webhookAuthType.getValue(),
              encrypted: true,
            })
          )
          return {
            apiKey: apiKey.getKey(),
            apiValue: apiKey.getValue(),
          }
        }
        if (webhookAuthType instanceof BearerToken) {
          const bearer = new BearerToken(
            new Secret({
              secret: webhookAuthType.getToken(),
              encrypted: true,
            })
          )
          return {
            token: bearer.getToken(),
          }
        }
        return {}
      }
      switch (webhookAuthentication.getAuthTypeToString()) {
        case 'basic_authorization':
          setAuthType('basic_authorization')
          setValue(
            'authentication.basic_auth.login.value',
            fillDefaultAuthDataForm().login as string
          )
          setValue(
            'authentication.basic_auth.password.value',
            fillDefaultAuthDataForm().password as string
          )
          break
        case 'bearer':
          setAuthType('bearer')
          setValue(
            'authentication.bearer_token.token.value',
            fillDefaultAuthDataForm().token as string
          )
          break
        case 'api_key':
          setAuthType('api_key')
          setValue(
            'authentication.api_key.key.value',
            fillDefaultAuthDataForm().apiKey as string
          )
          setValue(
            'authentication.api_key.value',
            fillDefaultAuthDataForm().apiValue as string
          )
          break
        case 'none':
          setAuthType('none')
          break
        default:
          setAuthType('none')
      }

      const eventSubscriptions = webhookData.getEventSubscriptions()

      const updatedEventSubscriptions = eventSubscriptions.map(
        (subscription) => ({
          eventType: subscription.getEventType() || '',
          versionType: subscription.getVersion() || 'v0',
          severityList: subscription.getSeverities()
            ? subscription.getSeverities().map((severity) => Severity[severity])
            : [],
        })
      )

      setValue('name', webhookData.getName())
      setValue('endpoint', updatedEndpoint)
      setValue('description', webhookData.getDescription())
      setEventList(updatedEventSubscriptions)
    }
  }, [webhookData, setValue])

  useEffect(() => {
    setIsSubmitDisabled(eventList.length === 0)
  }, [eventList])

  return (
    <Drawer
      open={
        action === SETTINGS_ACTIONS_ROUTE_SEGMENTS.ADD_WEBHOOK ||
        action === SETTINGS_ACTIONS_ROUTE_SEGMENTS.EDIT_WEBHOOK
      }
      onClose={closeDialog}
    >
      <DrawerHeader
        title={
          action === SETTINGS_ACTIONS_ROUTE_SEGMENTS.ADD_WEBHOOK
            ? 'Add Webhook'
            : 'Edit Webhook'
        }
        handleCloseDrawer={closeDialog}
      />
      <DrawerContent isEmpty={false}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack p={2.5} spacing={2}>
            <FormControl fullWidth>
              <Label>Webhook Name</Label>
              <TextField
                {...register('name', {
                  required: 'Webhook name is required',
                })}
                placeholder="Webhook Name"
                fullWidth
                error={!!errors.name}
                helperText={errors.name?.message}
              />
            </FormControl>
            <Grid2 container spacing={2}>
              <Grid2 size={6}>
                <FormControl fullWidth>
                  <Label>Endpoint URL</Label>
                  <TextField
                    {...register('endpoint', {
                      required: 'Endpoint URL is required',
                      onChange: (e) => {
                        const strippedUrl = stripProtocol(e.target.value)
                        e.target.value = strippedUrl
                      },
                    })}
                    placeholder="api.example.com/webhook"
                    fullWidth
                    error={!!errors.endpoint}
                    helperText={errors.endpoint?.message}
                    slotProps={{
                      input: {
                        startAdornment: (
                          <InputAdornment position="start">
                            https://
                          </InputAdornment>
                        ),
                      },
                    }}
                    sx={{ mr: 0 }}
                  />
                </FormControl>
              </Grid2>
              <Grid2 size={6}>
                <FormControl fullWidth>
                  <Label>Authentication Option</Label>
                  <BasicSelect
                    options={authorizationOptions}
                    onChange={(value) => {
                      setValue('authentication', value as AuthenticationType)
                      setAuthType(value as AuthType)
                    }}
                    value={authType}
                  />
                </FormControl>
              </Grid2>
            </Grid2>

            {renderAuthenticationFields()}

            <FormControl fullWidth>
              <Label>Description</Label>
              <TextField
                {...register('description')}
                placeholder="Description"
                fullWidth
                error={!!errors.description}
                helperText={errors.description?.message}
              />
            </FormControl>

            <Divider sx={{ py: 1 }} />

            <DrawerSubHeader py={1}>Select events</DrawerSubHeader>

            <FormControl fullWidth>
              <Label>Event Type</Label>
              <BasicSelect
                options={eventTypeList}
                placeholder="Event Type"
                onChange={(value) => {
                  setValue(
                    'event_subscriptions.0.event_type',
                    value as EventTypeConstant
                  )
                  setEventType(value as EventTypeConstant)
                }}
                value={eventType}
              />
            </FormControl>

            <Grid2 container spacing={2}>
              <Grid2 size={6}>
                <FormControl fullWidth>
                  <Label>Version</Label>
                  <BasicSelect
                    options={versionOptions}
                    onChange={(value) => {
                      setValue(
                        'event_subscriptions.0.version',
                        value as VersionType
                      )
                      setVersionType(value as VersionType)
                    }}
                    value={versionType}
                    disabled
                  />
                </FormControl>
              </Grid2>
              <Grid2 size={6}>
                <FormControl fullWidth>
                  <Label>Severities</Label>
                  <FilterMultiSelect
                    options={severityLists}
                    value={severityList}
                    onChange={handleSeverityChange}
                    label="Select Severities"
                    width={320}
                    disabled={!eventType}
                  />
                </FormControl>
              </Grid2>
            </Grid2>
            <Button
              type="button"
              variant="text"
              color="inherit"
              size="medium"
              onClick={handleAddEvent}
              disabled={isAddDisabled}
            >
              Add
            </Button>
            {eventList.length > 0 && (
              <table>
                <thead>
                  <tr>
                    <th>Event type</th>
                    <th>Version</th>
                    <th>Severity</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {eventList.map((item, index) => (
                    <tr key={index}>
                      <td>{getWebhooksEventType(item.eventType)}</td>
                      <td>{item.versionType}</td>
                      <td>{item.severityList.join(', ')}</td>
                      <td>
                        <DeleteIcon
                          onClick={() => handleDeleteEvent(index)}
                          cursor={'pointer'}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                mt: 2,
              }}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={
                  action === SETTINGS_ACTIONS_ROUTE_SEGMENTS.ADD_WEBHOOK
                    ? isSubmitDisabled
                    : false
                }
              >
                {action === SETTINGS_ACTIONS_ROUTE_SEGMENTS.ADD_WEBHOOK
                  ? 'Add Webhook'
                  : 'Update Webhook'}
              </Button>
            </Box>
          </Stack>
        </form>
      </DrawerContent>
    </Drawer>
  )
}

export default AddWebhookDrawer
