import React, { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import FilterMultiSelect, {
  MultiSelectOption,
} from 'ui-v2/src/components/ui/inputs/multiple-select-filter'
import { ASSETS_VULNERABILITIES_FILTERS_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import { getRiskName } from '../utils'
import SelectLabelText from 'ui-v2/src/components/ui/data-display/select-label-text'

export interface FilterComponentProps {
  options: Array<string>
}

const RiskFilter: React.FC<FilterComponentProps> = ({ options }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const awsRiskList =
    searchParams.get(ASSETS_VULNERABILITIES_FILTERS_MAP.RISK)?.split(',') || []

  const awsRiskOptions = useMemo(() => {
    return options.map((awsRiskOption) => ({
      value: awsRiskOption,
      label: getRiskName(awsRiskOption),
    }))
  }, [options])

  const handleAwsRiskChange = (
    newAwsRiskList: Array<MultiSelectOption['value']>
  ) => {
    if (newAwsRiskList.length === 0) {
      searchParams.delete(ASSETS_VULNERABILITIES_FILTERS_MAP.RISK)
    } else {
      searchParams.set(
        ASSETS_VULNERABILITIES_FILTERS_MAP.RISK,
        newAwsRiskList.join(',')
      )
    }
    setSearchParams(searchParams)
  }
  return (
    <FilterMultiSelect
      options={awsRiskOptions}
      value={awsRiskList}
      onChange={handleAwsRiskChange}
      width={180}
      label={
        <SelectLabelText
          selectedValueLength={awsRiskList?.length}
          optionsLength={awsRiskOptions?.length}
          text="Risk Type"
        />
      }
    />
  )
}

export default RiskFilter
