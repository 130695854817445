/* eslint-disable import/no-extraneous-dependencies */
import Link from 'ui-v2/src/components/ui/data-display/link'
import StatusChip from 'ui-v2/src/components/ui/data-display/chip'
import { IconButton, Stack } from '@mui/material'
import { ContentCopy } from '@mui/icons-material'
import { copyToClipboard } from 'ui-v2/src/lib/helpers/system.helper'
import { LangAwsRegionConstant } from 'ui-v2/src/lib/constants/lang.constant'
import { formatDateToUtc } from 'ui-v2/src/lib/helpers/time.helper'
import { InstanceOutput } from 'blues-corejs/dist'
import { Column } from 'ui-v2/src/components/ui/data-table/base-table'

export const getInstancesVulnerabilitiesColumns = (): Array<
  Column<InstanceOutput>
> => [
  {
    key: 'instanceId',
    header: 'Risk',
    render: (row) => {
      const status = (() => {
        switch (row.riskVulnerabilities) {
          case 0:
            return {
              type: 'success' as const,
              label: 'Low',
            }
          case 1:
            return {
              type: 'warning' as const,
              label: 'Medium',
            }
          case 2:
            return {
              type: 'error' as const,
              label: 'High',
            }
          default:
            return {
              type: 'error' as const,
              label: 'High',
            }
        }
      })()

      return <StatusChip {...status} />
    },
    width: 80,
    align: 'center',
  },
  {
    key: 'instanceAwsId',
    header: 'Instance',
    render: (row) => (
      <Link to={`/${row.instanceId}`} preservePath preserveSearchParams>
        {row.instanceAwsId}
      </Link>
    ),
  },
  {
    key: 'volumeVulnerabilities',
    header: 'Misconfiguration',
    render: (row) =>
      row.volumeVulnerabilities
        .map((volumeVulnerabilities) => volumeVulnerabilities)
        .join(', '),
    wrap: true,
  },
  {
    key: 'accountId',
    header: 'Account ID',
    render: (row) => (
      <Stack direction={'row'} alignItems={'center'} gap={1}>
        {row.accountId}
        <IconButton
          onClick={() => copyToClipboard(row.accountId)}
          size="small"
          sx={{
            padding: '6px',
            '& svg': { fontSize: 16 },
          }}
        >
          <ContentCopy />
        </IconButton>
      </Stack>
    ),
  },
  {
    key: 'region',
    header: 'Region',
    render: (row) => {
      const region = LangAwsRegionConstant.get(row.region)
      return region?.en?.single ?? ''
    },
  },
  {
    key: 'osKind',
    header: 'Attached Volumes',
    render: (row) => row.osKind,
  },
  {
    key: 'attachedVolumesCount',
    header: 'Attached Volumes',
    render: (row) => row.attachedVolumesCount,
  },
  {
    key: 'instanceState',
    header: 'Status',
    render: (row) => row.instanceState,
  },
  {
    key: 'lastBackup',
    header: 'Last Backup',
    render: (row) => formatDateToUtc(row.lastBackup?.backup.createdAt),
  },
]
