import StatusChip from 'ui-v2/src/components/ui/data-display/chip'
import Link from 'ui-v2/src/components/ui/data-display/link'
import { Column } from 'ui-v2/src/components/ui/data-table/base-table'
import { formatDateToUtc } from 'ui-v2/src/lib/helpers/time.helper'
import { OvaServerInventoryTableRow } from 'ui-v2/src/lib/models/assets/ova-server'

export const getInventoryServerColumns = (): Array<
  Column<OvaServerInventoryTableRow>
> => [
  {
    key: 'isUnhealthy',
    header: 'Health',
    render: (row) => (
      <StatusChip type={row.isUnhealthy ? 'error' : 'success'} />
    ),
    width: 80,
    align: 'center',
  },
  {
    key: 'name',
    header: 'Server',
    render: (row) => (
      <Link to={`/${row.id}`} preservePath preserveSearchParams>
        {row.name}
      </Link>
    ),
  },
  {
    key: 'ovaAccountId',
    header: 'Ova Account ID',
  },
  {
    key: 'lastCleanBackupDate',
    header: 'Last Clean Backup',
    render: (row) => formatDateToUtc(row.lastCleanBackupDate),
  },
]
