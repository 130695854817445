import { useEffect, useMemo, useRef } from 'react'
import * as echarts from 'echarts'
import { Box, useTheme } from '@mui/material'
import { getReadableTuples } from './utils'

interface WebhookData {
  webhookId: string
  successfulInvocations: number
  unsuccessfulInvocations: number
}

interface WebhookInvocationItem {
  webhookInvocationDataListList: Array<WebhookData>
}

interface DataArrays {
  successData: Array<Array<number>>
  failureData: Array<Array<number>>
}

interface WebhookResult {
  success: Array<number>
  failure: Array<number>
}

const createDataArrays = (webhookIds: Array<string>): DataArrays => ({
  successData: Array(webhookIds.length)
    .fill(0)
    .map(() => []),
  failureData: Array(webhookIds.length)
    .fill(0)
    .map(() => []),
})

const handleEmptyInvocations = (
  webhookIds: Array<string>,
  successData: Array<Array<number>>,
  failureData: Array<Array<number>>
): void => {
  webhookIds.forEach((_, index) => {
    successData[index]?.push(0)
    failureData[index]?.push(0)
  })
}

const processWebhookData = (
  webhookDataList: Array<WebhookData>,
  webhookIds: Array<string>,
  successData: Array<Array<number>>,
  failureData: Array<Array<number>>
): void => {
  const processedIds = new Set<string>()

  webhookDataList.forEach((data) => {
    const index = webhookIds.indexOf(data.webhookId)
    if (index !== -1) {
      successData[index]?.push(data.successfulInvocations)
      failureData[index]?.push(
        data.unsuccessfulInvocations === 0 ? 0 : -data.unsuccessfulInvocations
      )
      processedIds.add(data.webhookId)
    }
  })

  webhookIds.forEach((id, index) => {
    if (!processedIds.has(id)) {
      successData[index]?.push(0)
      failureData[index]?.push(0)
    }
  })
}

const transformToWebhookData = (
  successData: Array<Array<number>>,
  failureData: Array<Array<number>>
): Array<WebhookResult> | any => {
  return successData.map((_, webhookIndex) => ({
    success: successData[webhookIndex],
    failure: failureData[webhookIndex],
  }))
}

const processWebhookInvocations = (
  invocationsList: Array<WebhookInvocationItem>,
  webhookIds: Array<string>
): Array<WebhookResult> => {
  const { successData, failureData } = createDataArrays(webhookIds)

  invocationsList.forEach((item) => {
    if (!item.webhookInvocationDataListList.length) {
      handleEmptyInvocations(webhookIds, successData, failureData)
    } else {
      processWebhookData(
        item.webhookInvocationDataListList,
        webhookIds,
        successData,
        failureData
      )
    }
  })

  return transformToWebhookData(successData, failureData)
}

export default function LineBarChart(props: {
  data: any
  timeRange: any
  webhookInvocations: any
  webhook: any
}) {
  const theme = useTheme()
  const chartRef = useRef(null)

  let maxSuccess = 0
  let minFailure = 0

  const { data, timeRange, webhookInvocations, webhook } = props
  const colors = ['#4DA3FF', '#5ED3C7', '#A3C43C', '#FF914D']

  const xAxisData = useMemo(() => {
    const readableTuples = getReadableTuples(timeRange)
    const startDates = readableTuples.map((item) => item.start)
    return startDates
  }, timeRange)

  const webhookIds = webhook.length
    ? webhook
    : data.map((webhookId: { id: any }) => webhookId.id)

  const filterWebhookList = useMemo(() => {
    if (!webhook.length) {
      return data
    }
    return data.filter((webhookList: { id: string }) =>
      webhook.includes(webhookList.id)
    )
  }, [webhook, data])

  const dataValues = useMemo(() => {
    if (!webhookInvocations.data) {
      return []
    }
    webhookInvocations.data.webhookInvocationsList.forEach(
      (webhookInvocationData: {
        webhookInvocationDataListList: Array<any>
      }) => {
        webhookInvocationData.webhookInvocationDataListList.forEach(
          (InvocationData) => {
            maxSuccess = Math.max(
              maxSuccess,
              InvocationData.successfulInvocations
            )
            const failureValue =
              InvocationData.unsuccessfulInvocations === 0
                ? 0
                : -InvocationData.unsuccessfulInvocations
            minFailure = Math.min(minFailure, failureValue)
          }
        )
      }
    )
    return processWebhookInvocations(
      webhookInvocations.data.webhookInvocationsList,
      webhookIds
    )
  }, [webhookInvocations, timeRange, data])

  const barSeries = filterWebhookList.flatMap(
    (item: { name: any }, index: number) => [
      {
        name: `${item.name} (Success)`,
        type: 'bar',
        stack: item.name,
        barWidth: 15,
        data: dataValues[index]?.success,
        itemStyle: {
          color: colors[index % colors.length],
          borderRadius: [8, 8, 0, 0],
        },
      },
      {
        name: `${item.name} (Failure)`,
        type: 'bar',
        stack: item.name,
        barWidth: 15,
        data: dataValues[index]?.failure,
        itemStyle: {
          color: colors[index % colors.length],
          borderRadius: [0, 0, 8, 8],
          opacity: 0.5,
        },
      },
    ]
  )

  useEffect(() => {
    const chart = echarts.init(chartRef.current)

    const option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        formatter: function (params: Array<any>) {
          const groupName = params[0].name
          let tooltip = `${groupName}<br/>`

          params.forEach(
            (param: {
              value: number
              seriesName: string | Array<string>
              marker: any
            }) => {
              const value = Math.abs(param.value)
              tooltip += `${param.marker} ${param.seriesName}: ${value}<br/>`
            }
          )

          return tooltip
        },
      },
      legend: {
        bottom: 0,
        data: barSeries.map((series: { name: any }) => series.name),
        textStyle: {
          color: theme.palette.mode === 'dark' ? '#D3D8DE' : '#727883',
          fontSize: 12,
        },
        itemWidth: 10,
        itemHeight: 10,
        itemStyle: {
          borderRadius: 10,
        },
      },
      grid: {
        left: '3%',
        right: '3%',
        bottom: '15%',
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        boundaryGap: true,
        data: xAxisData,
        axisLabel: {
          color: theme.palette.mode === 'dark' ? '#ffffff' : '#727883',
        },
      },
      yAxis: {
        type: 'value',
        min: minFailure,
        max: maxSuccess,
        axisLabel: {
          color: theme.palette.mode === 'dark' ? '#ffffff' : '#727883',
          formatter: function (value: number) {
            return Math.abs(value)
          },
        },
      },
      series: barSeries,
    }

    chart.setOption(option)

    const handleResize = () => {
      chart.resize()
    }

    window.addEventListener('resize', handleResize)

    return () => {
      chart.dispose()
      window.removeEventListener('resize', handleResize)
    }
  }, [
    theme.palette.mode,
    data,
    timeRange,
    webhookInvocations,
    webhookIds,
    filterWebhookList,
  ])

  return (
    <Box
      ref={chartRef}
      sx={{
        position: 'relative',
        width: '100%',
        height: '400px',
      }}
    />
  )
}
