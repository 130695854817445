import * as React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'

import ProtectionPolicies from './protection-policies/index'

import {
  SETTINGS_ROUTE_SEGMENTS,
  POLICIES_SETTINGS_ROUTE_SEGMENTS,
} from 'ui-v2/src/lib/constants/route-segments.constant'

interface TabPanelProps {
  children?: React.ReactNode
  value: string
  slug: string
}

function TabPanel(props: TabPanelProps) {
  const { children, value, slug, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== slug}
      id={`tabpanel-${slug}`}
      aria-labelledby={`tab-${slug}`}
      {...other}
    >
      {value === slug && <Box sx={{ py: 2 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(slug: string) {
  return {
    id: `tab-${slug}`,
    'aria-controls': `tabpanel-${slug}`,
  }
}

const SUB_TAB_MAP = {
  protection: {
    label: 'Protection Policies',
    component: <ProtectionPolicies />,
    slug: POLICIES_SETTINGS_ROUTE_SEGMENTS.PROTECTION_POLICIES,
  },
}

export default function Policies() {
  const navigate = useNavigate()
  const { subTab } = useParams<{
    subTab: (typeof POLICIES_SETTINGS_ROUTE_SEGMENTS)[keyof typeof POLICIES_SETTINGS_ROUTE_SEGMENTS]
  }>()

  const defaultPath = `/settings/${SETTINGS_ROUTE_SEGMENTS.POLICIES}/${POLICIES_SETTINGS_ROUTE_SEGMENTS.PROTECTION_POLICIES}`

  React.useEffect(() => {
    if (!subTab) {
      navigate(defaultPath, { replace: true })
      return
    }

    if (!Object.values(SUB_TAB_MAP).some((t) => t.slug === subTab)) {
      navigate(defaultPath, { replace: true })
      return
    }
  }, [])

  if (!subTab || !Object.values(SUB_TAB_MAP).some((t) => t.slug === subTab)) {
    return null
  }

  const handleChange = (
    _: React.SyntheticEvent,
    newSlug: (typeof SETTINGS_ROUTE_SEGMENTS)[keyof typeof SETTINGS_ROUTE_SEGMENTS]
  ) => {
    navigate(`/settings/${SETTINGS_ROUTE_SEGMENTS.POLICIES}/${newSlug}`)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          borderBottom: 1,
          borderBottomColor: 'divider',
        }}
      >
        <Tabs
          value={subTab}
          onChange={handleChange}
          sx={(theme) => ({
            '& .MuiTab-root.Mui-selected': {
              color: theme.palette.text.primary,
            },
          })}
        >
          {Object.values(SUB_TAB_MAP).map((s) => (
            <Tab
              key={s.slug}
              value={s.slug}
              label={s.label}
              sx={{ textTransform: 'capitalize' }}
              {...a11yProps(s.slug)}
            />
          ))}
        </Tabs>
      </Box>
      {Object.entries(SUB_TAB_MAP).map(([key, value]) => (
        <TabPanel key={key} value={subTab} slug={value.slug}>
          {value.component}
        </TabPanel>
      ))}
    </Box>
  )
}
