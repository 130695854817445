/* eslint-disable import/no-extraneous-dependencies */
import { Stack } from '@mui/material'
import {
  listEc2InventoryPossibleFilters,
  ListInventoryInstances,
} from 'blues-corejs/dist'
import React, { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import ClientTable from 'ui-v2/src/components/ui/data-table/client-pagination-table'
import SearchBar from 'ui-v2/src/components/ui/inputs/search-bar'
import { useInventoryTablesFilters } from 'ui-v2/src/hooks/filters/inventory-tables'
import { useAssetsLiveQuery } from 'ui-v2/src/hooks/queries/assets'
import {
  INVENTORY_TABLES_FILTERS_MAP,
  INVENTORY_VIEW_PARAM,
  INVENTORY_VIEW_PARAM_VALUES,
  SEARCH_PARAM,
} from 'ui-v2/src/lib/constants/assets.constant'
import DataHelper from 'ui-v2/src/lib/helpers/data.helper'
import { getInventoryEc2Columns } from './column-config'
import InventoryTablesFilters from '../filters'
import { initialPrioritySortForEC2Table } from './priority-sort'

const Ec2Inventory = () => {
  const filters = useInventoryTablesFilters()

  const [searchParams] = useSearchParams()
  const search = searchParams.get(SEARCH_PARAM)
  const view = searchParams.get(INVENTORY_VIEW_PARAM)

  const { data: assetsLiveData, isLoading } = useAssetsLiveQuery()

  const { possibleEc2Filters } = useMemo(() => {
    if (!assetsLiveData) {
      return { possibleEc2Filters: {} }
    }

    return new listEc2InventoryPossibleFilters({
      Ec2s: assetsLiveData.ec2Instances,
    }).execute()
  }, [assetsLiveData])

  const scans = useMemo(() => {
    if (!assetsLiveData) {
      return []
    }

    return (
      assetsLiveData.scans.flatMap(
        (scan) =>
          scan.ransomwareScan ??
          scan.malwareScan ??
          scan.filesystemCheckScan ??
          []
      ) ?? []
    )
  }, [assetsLiveData])

  const instances = useMemo(() => {
    if (!assetsLiveData) {
      return []
    }

    const { instances: inventoryInstances } = new ListInventoryInstances({
      instances: assetsLiveData.ec2Instances,
      volumes: assetsLiveData.ebsVolumes,
      firstBackups: assetsLiveData.firstBackups.backups,
      lastBackups: assetsLiveData.lastBackups.backups,
      lastEbsSnapshots: assetsLiveData.lastEbsSnapshots,
      threats: assetsLiveData.threats,
      policies: [],
      lastScans: scans,
    }).execute(DataHelper.getDataForDashboardFilter(filters, search ?? ''))

    if (view === INVENTORY_VIEW_PARAM_VALUES.CLEAN) {
      return inventoryInstances.filter((instance) => !instance.isInfected)
    }

    if (view === INVENTORY_VIEW_PARAM_VALUES.RPO) {
      return inventoryInstances.filter((instance) => instance.rpo)
    }

    return inventoryInstances
  }, [assetsLiveData, JSON.stringify(filters), search, view, scans])

  const sortedInstances = useMemo(() => {
    return initialPrioritySortForEC2Table(
      instances,
      view === INVENTORY_VIEW_PARAM_VALUES.RPO
    )
  }, [instances, view])

  const columns = useMemo(getInventoryEc2Columns, [])

  return (
    <Stack gap={1}>
      <Stack direction="row" gap={1} alignItems={'center'}>
        <SearchBar />
      </Stack>

      <InventoryTablesFilters
        filters={[
          INVENTORY_TABLES_FILTERS_MAP.ACCOUNT_ID,
          INVENTORY_TABLES_FILTERS_MAP.REGION,
          INVENTORY_TABLES_FILTERS_MAP.POLICY,
        ]}
        filterValues={{
          [INVENTORY_TABLES_FILTERS_MAP.REGION]: possibleEc2Filters.regions,
          [INVENTORY_TABLES_FILTERS_MAP.ACCOUNT_ID]:
            possibleEc2Filters.accountIds,
          [INVENTORY_TABLES_FILTERS_MAP.POLICY]:
            possibleEc2Filters.covered?.map((option) => option.toString()),
        }}
      />
      <ClientTable
        data={sortedInstances}
        columns={columns}
        isLoading={isLoading}
      />
    </Stack>
  )
}

export default Ec2Inventory
