import { GenericHostTableRow } from 'ui-v2/src/lib/models/assets'
import { formatDateToUtc } from 'ui-v2/src/lib/helpers/time.helper'
import Link from 'ui-v2/src/components/ui/data-display/link'
import { Column } from 'ui-v2/src/components/ui/data-table/base-table'
import StatusChip from '../../../components/ui/data-display/chip'

export const getGenericHostColumns = (): Array<Column<GenericHostTableRow>> => [
  {
    key: 'isUnhealthy',
    header: 'Health',
    render: (row) => (
      <StatusChip type={row.isUnhealthy ? 'error' : 'success'} />
    ),
    width: 80,
    align: 'center',
  },
  {
    key: 'name',
    header: 'Host Name',
    render: (row) => (
      <Link to={`/${row.id}`} preservePath preserveSearchParams>
        {row.name}
      </Link>
    ),
    sortable: true,
  },
  {
    key: 'threats',
    header: 'Threats Found',
  },
  {
    key: 'lastScanDate',
    header: 'Last Scan',
    render: (row) => formatDateToUtc(row.lastScanDate),
  },
]
