import dayjs, { Dayjs } from 'dayjs'
import duration from 'dayjs/plugin/duration'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import isLeapYear from 'dayjs/plugin/isLeapYear'
import customParseFormat from 'dayjs/plugin/customParseFormat'

import { DateRangeTuple, Nullable } from '../engine-types'
/* eslint-disable import/no-cycle */
import { DEFAULT_TIME_ZONE, EMPTY_DATE_RANGE } from '../constants/time.constant'
import ReportDateRangeConstant from '../constants/report-date-range.constant'

import { Option, TimeRangeSelection } from '../models/reports'
import TimeFormatConstants from '../constants/time-format.constant'
import { TimeRangeInSeconds } from '../models/types'

dayjs.extend(duration)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(quarterOfYear)
dayjs.extend(isLeapYear)
dayjs.extend(customParseFormat)

export const secondsToDate = (seconds: number | undefined): Date => {
  if (!seconds) {
    return new Date(0)
  }

  return new Date(seconds * 1000)
}

export const millisecondsToDate = (milliseconds: number | undefined): Date => {
  if (!milliseconds) {
    return new Date(0)
  }

  return new Date(milliseconds)
}

export const formatDurationFromDate = (date: Date | undefined): string => {
  if (!date) {
    return ''
  }

  const now = dayjs()
  const inputDate = dayjs(date)
  const diffInSeconds = now.diff(inputDate, 'second')

  const dur = dayjs.duration(diffInSeconds * 1000)
  const days = dur.days()
  const hours = dur.hours()
  const minutes = dur.minutes()
  const parts = []

  if (days) {
    parts.push(`${days}d`)
  }
  if (hours) {
    parts.push(`${hours}h`)
  }
  if (minutes) {
    parts.push(`${minutes}m`)
  }

  return parts.join(' ')
}

export const formatMilliseconds = (ms: number): string => {
  const dur = dayjs.duration(ms)
  const days = Math.floor(dur.asDays())
  const hours = dur.hours()
  const minutes = dur.minutes()

  const parts = []

  if (days > 0) {
    parts.push(`${days}d`)
  }
  if (hours > 0) {
    parts.push(`${hours}h`)
  }
  if (minutes > 0) {
    parts.push(`${minutes}m`)
  }

  return parts.join(' ') || '0m'
}

export const formatDateToUtc = (
  date: Date | undefined | null,
  format = 'MM/DD/YYYY hh:mm A (UTC)'
): string => {
  if (!date) {
    return ''
  }

  return dayjs(date).utc().format(format)
}

export const formatTimeDifference = (date1: Date, date2: Date) => {
  const diffInMinutes = dayjs(date2).diff(dayjs(date1), 'minute')

  const hours = Math.floor(diffInMinutes / 60)
  const minutes = diffInMinutes % 60

  return `${hours.toString().padStart(2, '0')}h:${minutes
    .toString()
    .padStart(2, '0')}m`
}

const NUMBER_OF_AMOUNT_MONTHS = 3

export class PeriodTimeRange {
  static currentMonthDateRange(tz: string): [dayjs.Dayjs, dayjs.Dayjs] {
    const to = dayjs().tz(tz).startOf('day')
    const from = to.startOf('month')

    return [from, to]
  }

  static lastMonthDateRange(tz: string): [dayjs.Dayjs, dayjs.Dayjs] {
    const fromCurrent = dayjs().tz(tz).subtract(1, 'month')
    const from = fromCurrent.startOf('month')
    const to = fromCurrent.endOf('month')

    return [from, to]
  }

  static currentWeekDateRange(tz: string): [dayjs.Dayjs, dayjs.Dayjs] {
    const to = dayjs().tz(tz).startOf('day')
    const from = to.startOf('week')

    return [from, to]
  }

  static lastWeekDateRange(tz: string): [dayjs.Dayjs, dayjs.Dayjs] {
    const fromCurrent = dayjs().tz(tz).subtract(1, 'week')
    const from = fromCurrent.startOf('week')
    const to = fromCurrent.endOf('week')

    return [from, to]
  }

  static dayBeforeDateRange(tz: string): [dayjs.Dayjs, dayjs.Dayjs] {
    const yesterday = dayjs().tz(tz).subtract(1, 'day')
    const from = yesterday.startOf('day')
    const to = yesterday.endOf('day')

    return [from, to]
  }

  static lastYearDateRange(tz: string): [dayjs.Dayjs, dayjs.Dayjs] {
    const fromCurrent = dayjs().tz(tz).subtract(1, 'year')
    const from = fromCurrent.startOf('year')
    const to = fromCurrent.endOf('year')

    return [from, to]
  }

  static currentYearDateRange(tz: string): [dayjs.Dayjs, dayjs.Dayjs] {
    const to = dayjs().tz(tz).startOf('day')
    const from = to.startOf('year')

    return [from, to]
  }

  static lastQuarterDateRange(tz: string): [dayjs.Dayjs, dayjs.Dayjs] {
    const lastQuarter = dayjs().tz(tz).subtract(1, 'quarter')
    const from = lastQuarter.startOf('quarter')
    const to = lastQuarter.endOf('quarter')

    return [from, to]
  }

  static currentQuarterDateRange(tz: string): [dayjs.Dayjs, dayjs.Dayjs] {
    const to = dayjs().tz(tz).startOf('day')
    const from = to.startOf('quarter')

    return [from, to]
  }

  static currentDay(tz: string): [dayjs.Dayjs, dayjs.Dayjs] {
    const from = dayjs().tz(tz).startOf('day')
    const to = dayjs().tz(tz).endOf('day')

    return [from, to]
  }

  static lastAmountMonthsDateRange(
    amount: number,
    tz: string
  ): [dayjs.Dayjs, dayjs.Dayjs] {
    const fromCurrent = dayjs().tz(tz).subtract(amount, 'month')
    const from = fromCurrent.startOf('day')
    const to = dayjs().tz(tz).endOf('day')

    return [from, to]
  }
}

export function getReportDateRangeByPeriod(
  value: Option,
  tz: string
): DateRangeTuple {
  let dateRange = EMPTY_DATE_RANGE()
  switch (value.value) {
    case ReportDateRangeConstant.Today:
      dateRange = PeriodTimeRange.currentDay(tz)
      break
    case ReportDateRangeConstant.Yesterday:
      dateRange = PeriodTimeRange.dayBeforeDateRange(tz)
      break
    case ReportDateRangeConstant.LastWeek:
      dateRange = PeriodTimeRange.lastWeekDateRange(tz)
      break
    case ReportDateRangeConstant.LastMonth:
      dateRange = PeriodTimeRange.lastMonthDateRange(tz)
      break
    case ReportDateRangeConstant.ThreeMonths:
      dateRange = PeriodTimeRange.lastAmountMonthsDateRange(
        NUMBER_OF_AMOUNT_MONTHS,
        tz
      )
      break
    case ReportDateRangeConstant.LastYear:
      dateRange = PeriodTimeRange.lastYearDateRange(tz)
      break
    case ReportDateRangeConstant.ThisWeek:
      dateRange = PeriodTimeRange.currentWeekDateRange(tz)
      break
    case ReportDateRangeConstant.ThisMonth:
      dateRange = PeriodTimeRange.currentMonthDateRange(tz)
      break
    case ReportDateRangeConstant.ThisYear:
      dateRange = PeriodTimeRange.currentYearDateRange(tz)
      break
  }

  return dateRange
}

export interface TimeZoneOption {
  value: string
}

export interface HourMinuteInterface {
  hour: number
  minute: number
  timezone: string
}

export const formatDate = (
  date: Date | undefined | null,
  format = 'MM/DD/YYYY h:mm A'
) => {
  if (!date) {
    return ''
  }

  return dayjs(date).format(format)
}

export const getDateRange = (days = 7) => {
  return {
    endAt: dayjs().endOf('day').unix(),
    startAt: dayjs().subtract(days, 'days').endOf('day').unix(),
  }
}
export const getDatePeriod = (
  value: string | number,
  tz: string
): DateRangeTuple => {
  const today = dayjs().tz(tz)

  switch (value) {
    case TimeRangeSelection.LAST_7_DAYS:
      return [today.clone().subtract(7, 'days'), today]
    case TimeRangeSelection.LAST_30_DAYS:
      return [today.clone().subtract(30, 'days'), today]
    default:
      return [today, today]
  }
}
export const momentToTimestamp = (value: Dayjs): number => {
  return value.utc().toDate().getTime()
}
export const timestampToMoment = (timestamp: number): Dayjs => {
  return dayjs.utc(timestamp)
}
export const momentNow = (): Dayjs => {
  return dayjs().utc()
}
export const momentFormat = (value: Dayjs, format: string): string => {
  return value.utc().format(format)
}
export const maybeSecondsToDate = (seconds?: number): Date | null => {
  if (!seconds) {
    return null
  }

  return new Date(seconds * 1000)
}
export const momentShortDatetimeFormat = (value: Nullable<Dayjs>): string => {
  if (!value) {
    return ''
  }
  return momentFormat(value, TimeFormatConstants.SHORT_DATETIME_FORMAT)
}
export interface DatetimeDataInterface {
  day: number
  time: HourMinuteInterface
}
export interface FirstRunDataInterface {
  runNow: boolean
  runOn: DatetimeDataInterface
}

export const getTimezoneConfig = (): string => {
  return DEFAULT_TIME_ZONE().value
}

export const getDateWithTimezone = (
  date: number,
  timezoneName?: string
): Dayjs => {
  return dayjs(date).tz(timezoneName ?? getTimezoneConfig())
}

export const format12HourTime = (hour: number, minute: number) => {
  const time = dayjs().hour(hour).minute(minute)

  return time.format('hh:mm A')
}

export const currentPlusHours = (hours: number): Dayjs => {
  return dayjs().utc().add(hours, 'hours')
}

const MILLISECONDS_IN_SECOND = 1000

const convertMillisecondsToSeconds = (milliseconds: number): number => {
  return Math.floor(milliseconds / MILLISECONDS_IN_SECOND)
}

export const transformDateRangeForApi = (
  dateRange: DateRangeTuple
): TimeRangeInSeconds => {
  return {
    start:
      convertMillisecondsToSeconds(
        dayjs(dateRange[0]).utc().startOf('day').valueOf()
      ) ?? 0,
    end:
      convertMillisecondsToSeconds(
        dayjs(dateRange[1]).utc().endOf('day').valueOf()
      ) ?? 0,
  }
}
