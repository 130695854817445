import React, { useRef, useEffect, useState, useMemo } from 'react'
import { Box } from '@mui/material'
import { ValueInterface } from 'ui-v2/src/lib/engine-types'
import StatusChip from 'ui-v2/src/components/ui/data-display/chip'
import { SEARCH_PARAM_TO_FILTER_NAME_MAP } from 'ui-v2/src/hooks/filters/assets-threats'

type ThreatListProps = {
  filters: Array<ValueInterface>
}

const ThreatList = ({ filters }: ThreatListProps) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [isOverflowing, setIsOverflowing] = useState(false)

  const chipsNames = useMemo(() => {
    return filters.flatMap((item: ValueInterface) => {
      const filterName =
        SEARCH_PARAM_TO_FILTER_NAME_MAP[
          item.name as keyof typeof SEARCH_PARAM_TO_FILTER_NAME_MAP
        ]

      if (!filterName) {
        return []
      }

      return (
        item.options
          ?.map((option) => option.name?.trim())
          .filter(
            (name): name is string => !!name && name.toLowerCase() !== 'all'
          ) || []
      )
    })
  }, [filters])

  useEffect(() => {
    const calculateOverflow = () => {
      const container = containerRef.current
      if (!container) {
        return
      }

      const containerWidth = container.clientWidth
      const totalWidth = Array.from(container.children).reduce(
        (width, chip) => width + (chip as HTMLElement).offsetWidth + 8,
        0
      )

      setIsOverflowing(totalWidth > containerWidth - 60)
    }

    calculateOverflow()
    window.addEventListener('resize', calculateOverflow)
    return () => window.removeEventListener('resize', calculateOverflow)
  }, [chipsNames.length])

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        flexGrow: 1,
        overflow: 'hidden',
      }}
    >
      <Box
        ref={containerRef}
        sx={{
          display: 'flex',
          gap: 1,
          flexWrap: 'nowrap',
          overflow: 'hidden',
          ...(isOverflowing && {
            maskImage:
              'linear-gradient(to right, black calc(100% - 80px), transparent 100%)',
          }),
        }}
      >
        {chipsNames.map((chipName, index) => (
          <StatusChip
            key={`${chipName}-${index}`}
            type="error"
            label={chipName}
            sx={{
              px: 0.5,
              flexShrink: 0,
            }}
          />
        ))}
      </Box>
    </Box>
  )
}

export default ThreatList
