import {
  FormControl,
  Grid2,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import Label from '../../../../components/ui/data-display/label'

import { getCurrentTenantName } from 'ui-v2/src/lib/helpers/system.helper'
import { BUILD_DATE } from '@lib/constants/app.constant'

const Tenant = () => {
  const tenantName = getCurrentTenantName()

  return (
    <Stack spacing={2.5} sx={{ paddingBottom: 2.5 }} maxWidth={592}>
      <Grid2 container spacing={2}>
        <Grid2 size={12}>
          <FormControl fullWidth>
            <Label>Name</Label>
            <TextField
              disabled
              defaultValue={tenantName}
              sx={(theme) => ({
                backgroundColor: theme.palette.background.paper,
              })}
              size="small"
            />
          </FormControl>
        </Grid2>
        <Grid2 size={12}>
          <FormControl fullWidth>
            <Label>Tenant Version</Label>
            <TextField
              disabled
              defaultValue={BUILD_DATE ? BUILD_DATE : ''}
              sx={(theme) => ({
                backgroundColor: theme.palette.background.paper,
              })}
              size="small"
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography variant="body2" fontWeight={400}>
                        Release:
                      </Typography>
                    </InputAdornment>
                  ),
                },
              }}
            />
          </FormControl>
        </Grid2>
      </Grid2>
    </Stack>
  )
}

export default Tenant
