import { useColorScheme } from '@mui/material/styles'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { styled } from '@mui/material/styles'
import LightMode from 'ui-v2/src/assets/icons/theme/light-mode'
import System from 'ui-v2/src/assets/icons/theme/system'
import DarkMode from 'ui-v2/src/assets/icons/theme/dark-mode'

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '50px',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'column',
  },
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.5),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '50%',
    padding: theme.spacing(0.25),
    transition: theme.transitions.create(
      ['background-color', 'border-color', 'color'],
      {
        duration: theme.transitions.duration.shortest,
      }
    ),
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  },
}))

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  '&.MuiToggleButton-root': {
    color: theme.palette.text.primary,
  },
  '&.Mui-selected': {
    color: theme.palette.primary.contrastText,
  },
}))

export enum ThemeMode {
  LIGHT = 'light',
  DARK = 'dark',
  SYSTEM = 'system',
}

const ThemeSwitcher = () => {
  const { mode, setMode } = useColorScheme()
  if (!mode) {
    return null
  }

  const handleChange = (
    _: React.MouseEvent<HTMLElement>,
    newMode: ThemeMode
  ) => {
    setMode(newMode)
  }

  const themes = [
    {
      value: ThemeMode.LIGHT,
      icon: LightMode,
    },
    {
      value: ThemeMode.SYSTEM,
      icon: System,
    },
    {
      value: ThemeMode.DARK,
      icon: DarkMode,
    },
  ] as const

  return (
    <StyledToggleButtonGroup
      value={mode}
      exclusive
      onChange={handleChange}
      aria-label="theme mode"
    >
      {themes.map(({ value, icon: Icon }) => (
        <StyledToggleButton
          key={value}
          value={value}
          aria-label={`${value} mode`}
        >
          <Icon />
        </StyledToggleButton>
      ))}
    </StyledToggleButtonGroup>
  )
}

export default ThemeSwitcher
