/* eslint-disable import/no-extraneous-dependencies */
import { InventoryInstanceOutput } from 'blues-corejs/dist/use_cases/list_inventory_instances/types'
import StatusChip from 'ui-v2/src/components/ui/data-display/chip'
import Link from 'ui-v2/src/components/ui/data-display/link'
import { Column } from 'ui-v2/src/components/ui/data-table/base-table'
import { LangAwsRegionConstant } from 'ui-v2/src/lib/constants/lang.constant'
import {
  formatDateToUtc,
  formatMilliseconds,
} from 'ui-v2/src/lib/helpers/time.helper'
import { AssetsEc2Base } from 'ui-v2/src/lib/use-cases/assets/ec2/ec2-base'

export const getInventoryEc2Columns = (): Array<
  Column<InventoryInstanceOutput>
> => [
  {
    key: 'isInfected',
    header: 'Health',
    render: (row) => <StatusChip type={row.isInfected ? 'error' : 'success'} />,
    width: 80,
    align: 'center',
  },
  {
    key: 'awsId',
    header: 'Instance ID',
    render: (row) => (
      <Link to={`/${row.uuid}`} preservePath preserveSearchParams>
        {row.awsId}
      </Link>
    ),
  },
  {
    key: 'instanceName',
    header: 'Instance',
    render: (row) => (
      <Link to={`/${row.uuid}`} preservePath preserveSearchParams>
        {row.instanceName}
      </Link>
    ),
  },
  {
    key: 'accountId',
    header: 'Account ID',
  },
  {
    key: 'region',
    header: 'Region',
    render: (row) => {
      const region = LangAwsRegionConstant.get(row.region)
      return region?.en?.single ?? ''
    },
  },
  {
    key: 'lastBackup',
    header: 'Last Backup',
    render: (row) => formatDateToUtc(row.lastBackup?.createdAt),
  },
  {
    key: 'lastScan',
    header: 'Last Scan',
    render: (row) => formatDateToUtc(row.lastScan),
  },
  {
    key: 'rpo',
    header: 'RPO',
    render: (row) => (row.rpo ? formatMilliseconds(row.rpo) : ''),
  },
  {
    key: 'instanceState',
    header: 'State',
    render: (row) => AssetsEc2Base.convertEc2State(row.instanceState),
  },
  {
    key: 'coveredByPolicesList',
    header: 'Policy Coverage',
    render: (row) => row.coveredByPolicesList.join(', '),
  },
]
