import { SortConfig } from 'ui-v2/src/components/ui/data-table/base-table'
import { S3_SortBy } from 'ui-v2/src/lib/grpc'
import { S3TableRow } from 'ui-v2/src/lib/models/assets'

const columnToProtoFieldMap: Partial<
  Record<keyof S3TableRow, S3_SortBy.SortField.Field>
> = {
  awsId: S3_SortBy.SortField.Field.FIELD_AWS_ID,
  awsAccountId: S3_SortBy.SortField.Field.FIELD_AWS_ACCOUNT_ID,
  awsRegion: S3_SortBy.SortField.Field.FIELD_AWS_REGION,
  state: S3_SortBy.SortField.Field.FIELD_STATE,
  backupPolicies: S3_SortBy.SortField.Field.FIELD_BACKUP_POLICIES,
}

export interface ProtoSortField {
  field: S3_SortBy.SortField.Field
  ascending: boolean
}

export interface ProtoSortBy {
  sortBy: Array<ProtoSortField>
}

export const convertS3SortConfigsToProto = (
  sortConfigs: Array<SortConfig>
): ProtoSortBy => {
  return {
    sortBy: sortConfigs
      .map((config) => {
        const protoField = columnToProtoFieldMap[config.key as keyof S3TableRow]
        if (protoField === undefined) {
          return null
        }
        return {
          field: protoField,
          ascending: config.direction === 'asc',
        }
      })
      .filter(
        (field): field is ProtoSortField =>
          field !== null &&
          typeof field === 'object' &&
          'field' in field &&
          'ascending' in field
      ),
  }
}
