/* eslint-disable import/no-extraneous-dependencies */
import { EfsInventoryOutput } from 'blues-corejs/dist/use_cases/list_efs_inventory/types'
import StatusChip from 'ui-v2/src/components/ui/data-display/chip'
import Link from 'ui-v2/src/components/ui/data-display/link'
import { Column } from 'ui-v2/src/components/ui/data-table/base-table'
import { LangAwsRegionConstant } from 'ui-v2/src/lib/constants/lang.constant'
import { formatDateToUtc } from 'ui-v2/src/lib/helpers/time.helper'
import { AssetsEfsBase } from 'ui-v2/src/lib/use-cases/assets/efs/efs-base'

export const getInventoryEfsColumns = (): Array<Column<EfsInventoryOutput>> => [
  {
    key: 'isInfected',
    header: 'Health',
    render: (row) => <StatusChip type={row.isInfected ? 'error' : 'success'} />,
    width: 80,
    align: 'center',
  },
  {
    key: 'awsId',
    header: 'Filesystem ID',
    render: (row) => (
      <Link to={`/${row.uuid}`} preservePath preserveSearchParams>
        {row.awsId}
      </Link>
    ),
  },
  {
    key: 'name',
    header: 'Filesystem',
    render: (row) => (
      <Link to={`/${row.uuid}`} preservePath preserveSearchParams>
        {row.name}
      </Link>
    ),
  },
  {
    key: 'accountId',
    header: 'Account ID',
  },
  {
    key: 'region',
    header: 'Region',
    render: (row) => {
      const region = LangAwsRegionConstant.get(row.region)
      return region?.en?.single ?? ''
    },
  },
  {
    key: 'lastScan',
    header: 'Last Scan',
    render: (row) => formatDateToUtc(row.lastScan),
  },
  {
    key: 'state',
    header: 'State',
    render: (row) => AssetsEfsBase.convertEfsState(row.state),
  },
  {
    key: 'backupPolicies',
    header: 'Policy Coverage',
    render: (row) => row.backupPolicies.join(', '),
  },
]
