import React, { useMemo } from 'react'
import { Button, Stack, Typography } from '@mui/material'
import AssetsDropdown from './ assets-dropdown'
import StatusDropdown from './status-dropdown'
import JobTypeDropdown from './job-type-dropdown'

import { useCloudConnectorJobFiltersQuery } from 'ui-v2/src/hooks/queries/jobs'

import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useAssetsLiveQuery } from 'ui-v2/src/hooks/queries/assets'
import { MultiSelectOption } from 'ui-v2/src/components/ui/inputs/multiple-select-filter'
import { Delete } from '@mui/icons-material'
import { useSearchParams } from 'react-router-dom'

import { TimeRangeInSeconds } from 'ui-v2/src/lib/models/types'

import DateRangePicker from 'ui-v2/src/components/ui/inputs/date-range-picker'

dayjs.extend(utc)

const MonitorFilter = ({
  startDate,
  endDate,
  timeRange,
}: MonitorFilterProps) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const { data: cloudConnectorJobFilters } =
    useCloudConnectorJobFiltersQuery(timeRange)

  const { data: liveAssetsData } = useAssetsLiveQuery()

  const assets = useMemo(() => {
    if (!liveAssetsData) {
      return
    }

    const liveEc2Instance = liveAssetsData.ec2Instances.map((ec2) => ({
      label: ec2.awsId,
      value: ec2.id,
    }))

    const ebsVolumes = liveAssetsData.ebsVolumes.map((ebs) => ({
      label: ebs.awsId,
      value: ebs.id,
    }))

    const s3Buckets = liveAssetsData.s3Buckets.map((s3Bucket) => ({
      label: s3Bucket.awsId,
      value: s3Bucket.id,
    }))

    const efs = liveAssetsData.efs.map((efsInstance) => ({
      label: efsInstance.awsId,
      value: efsInstance.id,
    }))

    const servers = liveAssetsData.genericHosts.map((genericHost) => ({
      label: genericHost.hostname,
      value: genericHost.id,
    }))

    const allLiveAssets = [
      ...liveEc2Instance,
      ...ebsVolumes,
      ...s3Buckets,
      ...efs,
      ...servers,
    ]

    const assetMap = new Map()

    allLiveAssets.forEach((asset) => {
      assetMap.set(asset.value, asset.label)
    })

    const assetIdsList = cloudConnectorJobFilters?.assetIdsList ?? []

    const options: Array<MultiSelectOption> = []

    assetIdsList.forEach((assetId) => {
      const findAssetLabel = assetMap.get(assetId)

      if (findAssetLabel) {
        options.push({
          label: findAssetLabel,
          value: assetId,
        })
      }
    })

    return options
  }, [cloudConnectorJobFilters, liveAssetsData])

  const handleDateSearchParams = (sDate: Dayjs, eDate: Dayjs) => {
    if (!dayjs(sDate).isValid() || !dayjs(eDate).isValid()) {
      return
    }

    searchParams.set('start', sDate.format('MM-DD-YYYY'))
    searchParams.set('end', eDate.format('MM-DD-YYYY'))

    setSearchParams(searchParams)
  }

  const handleClearFilters = () => {
    setSearchParams(new URLSearchParams())
  }

  return (
    <Stack
      direction="row"
      flexWrap={'wrap'}
      rowGap={1}
      columnGap={1}
      alignItems={'center'}
    >
      <Typography
        variant="body1"
        fontSize={14}
        fontWeight={600}
        paddingLeft={'0.5rem'}
      >
        Filter
      </Typography>

      <AssetsDropdown assets={assets ?? []} />

      <StatusDropdown />

      <JobTypeDropdown />

      <DateRangePicker
        key={startDate.toString() + endDate.toString()}
        initialDateRange={{
          startDate: dayjs(startDate),
          endDate: dayjs(endDate),
        }}
        onChange={(range) => {
          if (range.startDate && range.endDate) {
            handleDateSearchParams(range.startDate, range.endDate)
          }
        }}
      />

      <Button
        disableElevation
        startIcon={<Delete />}
        variant="contained"
        color="inherit"
        sx={(theme) => ({
          textTransform: 'capitalize',
          color: theme.palette.text.muted,
          backgroundColor: theme.palette.background.paper,
          height: 36,

          '&:hover': {
            color: theme.palette.text.primary,
          },
        })}
        onClick={handleClearFilters}
      >
        Clear
      </Button>
    </Stack>
  )
}
interface MonitorFilterProps {
  startDate: Dayjs
  endDate: Dayjs
  timeRange: TimeRangeInSeconds
}
export default MonitorFilter
