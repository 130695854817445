import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { ASSETS_THREATS_FILTERS_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import { Delete } from '@mui/icons-material'
import { Button, Stack, Typography } from '@mui/material'
import { filterOptions } from './config'

type FilterKey =
  (typeof ASSETS_THREATS_FILTERS_MAP)[keyof typeof ASSETS_THREATS_FILTERS_MAP]

type FilterValues = {
  [K in FilterKey]?: Array<string>
}

interface AssetsThreatsFiltersProps {
  filters: Array<
    (typeof ASSETS_THREATS_FILTERS_MAP)[keyof typeof ASSETS_THREATS_FILTERS_MAP]
  >
  filterValues: FilterValues
}

const AssetsThreatsFilters: React.FC<AssetsThreatsFiltersProps> = ({
  filters,
  filterValues,
}) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const handleClearFilters = () => {
    const newSearchParams = new URLSearchParams()
    const timeParam = searchParams.get('t')
    if (timeParam) {
      newSearchParams.set('t', timeParam)
    }
    setSearchParams(newSearchParams)
  }
  return (
    <Stack direction="column" spacing={1}>
      <Stack
        direction="row"
        flexWrap={'wrap'}
        rowGap={1}
        columnGap={1}
        alignItems={'center'}
      >
        <Typography
          variant="body1"
          fontSize={14}
          fontWeight={600}
          paddingLeft={'0.5rem'}
        >
          Filter
        </Typography>

        {filters.map((filter) => {
          const FilterComponent = filterOptions[filter]
          return (
            <React.Fragment key={filter}>
              <FilterComponent options={filterValues[filter] || []} />
            </React.Fragment>
          )
        })}

        <Button
          disableElevation
          startIcon={<Delete />}
          variant="contained"
          color="inherit"
          sx={(theme) => ({
            textTransform: 'capitalize',
            color: theme.palette.text.muted,
            backgroundColor: theme.palette.background.paper,
            height: 36,

            '&:hover': {
              color: theme.palette.text.primary,
            },
          })}
          onClick={handleClearFilters}
        >
          Clear
        </Button>
      </Stack>
    </Stack>
  )
}

export default AssetsThreatsFilters
