import { Box, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import { useEffect, useMemo, useRef } from 'react'
import * as echarts from 'echarts'
import type { EChartsOption } from 'echarts'
import { Anomaly, Finding } from 'ui-v2/src/lib/models/anomalies'

const CHART_HEIGHT = '140px'

type Styles = {
  [key: string]: string | number
}

const AssetsDrawerEntropyTrend = ({
  data,
  styles,
}: {
  data: Array<Anomaly>
  styles?: Styles
}) => {
  const theme = useTheme()
  const chartRef = useRef(null)

  const formattedData = useMemo(() => {
    const calculateTotalFiles = (findings: Array<Finding>) =>
      findings.reduce((acc, cur) => cur.numberOfFiles + acc, 0)

    return data.length > 0
      ? data
          .map((anomaly) => ({
            value: [
              dayjs(anomaly.createdAt).valueOf(),
              calculateTotalFiles(anomaly.findings),
            ],
          }))
          .sort((a, b) => (a.value[0] ?? 0) - (b.value[0] ?? 0))
      : [
          {
            value: [dayjs().subtract(7, 'days').endOf('day').valueOf(), 0],
          },
          {
            value: [dayjs().endOf('day').valueOf(), 0],
          },
        ]
  }, [data])

  useEffect(() => {
    if (!chartRef.current) {
      return
    }

    const chart = echarts.init(chartRef.current as HTMLDivElement)

    const option: EChartsOption = {
      textStyle: {
        color: theme.palette.text.muted,
      },
      tooltip: {
        trigger: 'axis',
        name: 'Files',
        formatter: function (params) {
          // @ts-ignore
          const date = dayjs(params[0].value[0]).format('MM/DD/YYYY hh:mm A')
          // @ts-ignore
          return `${date}: ${params[0].value[1]} file(s)`
        },
      },
      grid: {
        left: '4%',
        right: '3%',
        bottom: '3%',
        top: '10%',
        containLabel: true,
        show: true,
        borderWidth: 1,
        borderColor: '#e4dbe940',
      },
      xAxis: {
        type: 'time',
        axisLine: {
          lineStyle: {
            color: theme.palette.mode === 'dark' ? '#ffffff' : '#727883',
          },
        },
        axisLabel: {
          color: theme.palette.mode === 'dark' ? '#ffffff' : '#727883',
          // @ts-ignore
          formatter: (value) => {
            return dayjs(value).format('MM/DD/YYYY hh:mm A')
          },
        },
        splitLine: {
          show: false,
        },
        show: false,
      },
      yAxis: {
        type: 'value',
        name: 'Files',
        nameLocation: 'middle',
        nameGap: 5,
        nameRotate: 90,
        nameTextStyle: { fontSize: '1rem' },
        min: 0,
        max: Math.max(
          ...formattedData
            .map((item) => item.value[1])
            .filter((value): value is number => value !== undefined)
        ),
        minInterval: 1,
        splitLine: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        axisLabel: {
          formatter: '{value}',
        },
      },
      series: [
        {
          name: 'Files',
          type: 'line',
          smooth: true,
          symbol: 'circle',
          symbolSize: 6,
          data: formattedData,
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: 'rgb(45, 59, 73)',
                },
              ],
            },
          },
          lineStyle: {
            color: '#1e90ff',
            width: 2,
          },
          itemStyle: {
            color: '#1e90ff',
          },
        },
      ],
    }

    chart.setOption(option)

    const handleResize = () => {
      chart.resize()
    }
    window.addEventListener('resize', handleResize)

    return () => {
      chart.dispose()
      window.removeEventListener('resize', handleResize)
    }
  }, [data, theme.palette.mode])

  return (
    <Box
      ref={chartRef}
      sx={{
        height: CHART_HEIGHT,
        backgroundColor: styles?.backgroundColor || '#152635',
      }}
    ></Box>
  )
}

export default AssetsDrawerEntropyTrend
