import { useSearchParams } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'
import {
  AssetsFilters,
  ListEbsFilters,
  PolicyCoverageState,
} from 'ui-v2/src/lib/models/assets'
import EnumHelper from 'ui-v2/src/lib/helpers/enum.helper'
import { TABLE_ROWS_PER_PAGE_OPTIONS } from 'ui-v2/src/lib/constants/ui.constant'
import {
  ASSET_FILTERS_MAP,
  NAME_TAG_KEY,
  SEARCH_PARAM,
} from 'ui-v2/src/lib/constants/assets.constant'
import { ListEbsRequest } from 'ui-v2/src/lib/grpc'

export const useAssetsEbsFilters = (): AssetsFilters<ListEbsFilters> => {
  const [pageSize, setPageSize] = useState<number>(
    TABLE_ROWS_PER_PAGE_OPTIONS[0]
  )
  const [pageToken, setPageToken] = useState<string | undefined>(undefined)
  const [searchParams] = useSearchParams()

  useEffect(() => {
    setPageToken(undefined)
  }, [searchParams])

  return useMemo(() => {
    return {
      filters: {
        pageSize,
        pageToken,
        search: searchParams.get(SEARCH_PARAM) ?? undefined,
        volumeStatesList: EnumHelper.filterToEnumValues(
          searchParams.get(ASSET_FILTERS_MAP.VOLUME_STATE)?.split(',') ?? [],
          ListEbsRequest.Initial.Filter.VolumeState
        ),
        accountIdsList: searchParams
          .get(ASSET_FILTERS_MAP.ACCOUNT_ID)
          ?.split(',')
          .filter(Boolean),
        regionsList: searchParams
          .get(ASSET_FILTERS_MAP.REGION)
          ?.split(',')
          .filter(Boolean),
        assetIdList: searchParams
          .get(ASSET_FILTERS_MAP.VOLUME_ID)
          ?.split(',')
          .filter(Boolean),
        tagsList: searchParams
          .get(ASSET_FILTERS_MAP.VOLUME_NAME)
          ?.split(',')
          .filter(Boolean)
          .map((name) => ({
            key: NAME_TAG_KEY,
            value: name,
          })),
        protectionPolicyCoverageStatesList: EnumHelper.filterToEnumValues(
          searchParams.get(ASSET_FILTERS_MAP.POLICY_COVERAGE)?.split(',') ?? [],
          PolicyCoverageState
        ),
      },
      setPageSize,
      setPageToken,
    }
  }, [pageSize, pageToken, searchParams, setPageSize, setPageToken])
}
