import { useMemo } from 'react'
import { Stack } from '@mui/material'
import { useAssetsLiveQuery } from 'ui-v2/src/hooks/queries/assets'
import { AssetsEc2TableData } from 'ui-v2/src/lib/use-cases/assets'
import { getEc2Columns } from './column-config'
import { useSearchParams } from 'react-router-dom'
import { useAssetsEc2Filters } from 'ui-v2/src/hooks/filters/assets'
import AssetFilters from '../filters'
import { ASSET_FILTERS_MAP } from 'ui-v2/src/lib/constants/assets.constant'
import CursorTable from 'ui-v2/src/components/ui/data-table/cursor-pagination-table'
import { useAssetsSearchQuery } from 'ui-v2/src/hooks/queries/search'
import { useSortParams } from 'ui-v2/src/hooks/sort-params'
import { convertEc2SortConfigsToProto } from './sort-mapping'

const AssetsEc2 = () => {
  const { sortConfigs } = useSortParams()

  const [searchParams] = useSearchParams()

  const { filters, setPageSize, setPageToken } = useAssetsEc2Filters()

  const { data: assetsLiveData, isLoading: isAssetsLiveDataLoading } =
    useAssetsLiveQuery()

  const { data: assetsEc2Data, isLoading: isAssetsEc2DataLoading } =
    useAssetsSearchQuery({
      ec2: {
        ...filters,
        ...convertEc2SortConfigsToProto(sortConfigs),
      },
      pageToken: filters.pageToken,
      pageSize: filters.pageSize,
    })

  const lastScans = useMemo(
    () =>
      assetsLiveData?.scans.flatMap((scan) => {
        if (scan.ransomwareScan) {
          return scan.ransomwareScan
        }
        if (scan.malwareScan) {
          return scan.malwareScan
        }
        if (scan.filesystemCheckScan) {
          return scan.filesystemCheckScan
        }
        return []
      }),
    [assetsLiveData]
  )

  const data = useMemo(() => {
    if (!assetsEc2Data || !assetsLiveData || !lastScans) {
      return undefined
    }

    return new AssetsEc2TableData({
      instances: assetsEc2Data.instancesList,
      volumes: assetsLiveData.ebsVolumes,
      threats: assetsLiveData.threats,
      scans: lastScans,
    }).getEc2TableData()
  }, [assetsEc2Data, assetsLiveData, lastScans])

  const columns = useMemo(getEc2Columns, [])

  return (
    <Stack spacing={1}>
      <AssetFilters
        showSearch
        filters={[
          ASSET_FILTERS_MAP.INSTANCE_NAME,
          ASSET_FILTERS_MAP.INSTANCE_ID,
          ASSET_FILTERS_MAP.ACCOUNT_ID,
          ASSET_FILTERS_MAP.REGION,
          ASSET_FILTERS_MAP.INSTANCE_STATE,
          ASSET_FILTERS_MAP.POLICY_COVERAGE,
        ]}
      />
      <CursorTable
        key={searchParams.toString()}
        data={data}
        columns={columns}
        isLoading={isAssetsEc2DataLoading || isAssetsLiveDataLoading}
        pageSize={filters.pageSize}
        pageToken={filters.pageToken}
        paginationCursors={assetsEc2Data?.pagination}
        setPageSize={setPageSize}
        setPageToken={setPageToken}
      />
    </Stack>
  )
}

export default AssetsEc2
