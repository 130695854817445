/* eslint-disable */
// // @ts-nocheck
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import * as echarts from 'echarts'
import { Box, Button, Typography, useTheme } from '@mui/material'
import ElastioLogo from '../../../../assets/logo/elastio-logo.svg'
import { RadarChartAxes } from 'ui-v2/src/lib/constants/ui.constant'
import {
  useAwsScanCoverageQuery,
  useAwsStorageMisconfigurationQuery,
} from 'ui-v2/src/hooks/queries/aws-stats'
import { useOvaAssetsScannedQuery } from 'ui-v2/src/hooks/queries/ova-statistics'
import { useAssetsLiveQuery } from 'ui-v2/src/hooks/queries/assets'
import { useGetFiltersForListAwsAssetSummariesQuery } from 'ui-v2/src/hooks/queries/entropy-detection'
import dayjs from 'dayjs'
import { CheckStatus } from 'blues-corejs/dist/models/backups/aws/awsb/types'
import { ResiliencePercentage, ResilienceWeightData } from '../radar-chart-card'

type RadarChartProps = {
  selectedSection: RadarChartAxes
  resilienceScore: number
  setSelectedSection: (section: RadarChartAxes) => void
  setResilienceScore: Dispatch<SetStateAction<number>>
  resilienceWeight: ResilienceWeightData
  resiliencePercentage: ResiliencePercentage | undefined
  setResiliencePercentage: Dispatch<
    SetStateAction<ResiliencePercentage | undefined>
  >
  setOpenScoreCalculationDrawer: (value: SetStateAction<boolean>) => void
}

export default function RadarChart({
  selectedSection,
  resilienceScore,
  resilienceWeight,
  setResilienceScore,
  setSelectedSection,
  setResiliencePercentage,
  setOpenScoreCalculationDrawer,
}: RadarChartProps) {
  const [hovered, setHovered] = useState(false)

  const { data: assetsLiveData, isLoading: isAssetsLiveDataLoading } =
    useAssetsLiveQuery()

  //Asset Coverage metric
  const { data: awsScanCoverageData, isLoading: isAwsScanCoverageDataLoading } =
    useAwsScanCoverageQuery({})
  const {
    data: ovaAssetsScannedData,
    isLoading: isOvaAssetsScannedDataLoading,
  } = useOvaAssetsScannedQuery({})

  const assetCoveragePercentage = useMemo(() => {
    if (!awsScanCoverageData || !ovaAssetsScannedData) {
      return undefined
    }

    const services = ['ebs', 'ec2', 'efs', 's3'] as const

    const awsCoveredCount = services.reduce(
      (sum, service) => sum + (awsScanCoverageData[service]?.covered ?? 0),
      0
    )

    const awsTotalCount = services.reduce(
      (sum, service) => sum + (awsScanCoverageData[service]?.total ?? 0),
      0
    )

    return Math.round((awsCoveredCount / (awsTotalCount || 1)) * 100)
  }, [awsScanCoverageData, ovaAssetsScannedData])

  //Ransomware Safety
  const ransomwareSafetyPercentage = useMemo(() => {
    if (!assetsLiveData || !ovaAssetsScannedData) {
      return undefined
    }

    const totalLiveAssets =
      assetsLiveData.ebsVolumes.length +
      assetsLiveData.ec2Instances.length +
      assetsLiveData.efs.length +
      assetsLiveData.genericHosts.length +
      assetsLiveData.s3Buckets.length

    const assetsWithThreatsSet = new Set()
    assetsLiveData.threats.forEach((threat) => {
      if (threat.source.asset?.assetId) {
        assetsWithThreatsSet.add(threat.source.asset?.assetId)
      }
    })

    const totalOvaServers = ovaAssetsScannedData.totalAssets
    const infectedOvaServers = ovaAssetsScannedData.infectedAssets

    const totalAssets = totalLiveAssets + totalOvaServers

    return Math.round(
      ((totalAssets - (assetsWithThreatsSet.size + infectedOvaServers)) /
        totalAssets || 1) * 100
    )
  }, [assetsLiveData, ovaAssetsScannedData])

  //Encryption Activity
  const awsAssetSummaryFilter = useMemo(
    () => ({
      startAt: Math.floor(dayjs().subtract(30, 'day').valueOf() / 1000),
      endAt: Math.floor(dayjs().valueOf() / 1000),
    }),
    []
  )

  const {
    data: getFiltersForListAwsAssetSummariesData,
    isLoading: isGetFiltersForListAwsAssetSummariesDataLoading,
  } = useGetFiltersForListAwsAssetSummariesQuery(awsAssetSummaryFilter)

  const encryptionIntegrityPercentage = useMemo(() => {
    if (!assetsLiveData || !getFiltersForListAwsAssetSummariesData) {
      return undefined
    }

    const total =
      assetsLiveData.ebsVolumes.length + assetsLiveData.s3Buckets.length

    const encryptionActivityCount =
      getFiltersForListAwsAssetSummariesData.ebsVolumes.length +
      getFiltersForListAwsAssetSummariesData.s3Buckets.length

    return Math.round((encryptionActivityCount / (total || 1)) * 100)
  }, [assetsLiveData, getFiltersForListAwsAssetSummariesData])

  //Storage Health
  const {
    data: awsStorageMisconfiurationData,
    isLoading: isAwsStorageMisconfiurationDataLoading,
  } = useAwsStorageMisconfigurationQuery({})

  const storageHealthPercentage = useMemo(() => {
    if (!awsStorageMisconfiurationData || !assetsLiveData) {
      return undefined
    }

    const misconfigurationCount =
      awsStorageMisconfiurationData.snapshotVulnerabilitiesList.length +
      awsStorageMisconfiurationData.volumeVulnerabilitiesList.length

    const totalCount =
      assetsLiveData.ebsVolumes.length + assetsLiveData.lastEbsSnapshots.length

    return Math.round((misconfigurationCount / (totalCount || 1)) * 100)
  }, [awsStorageMisconfiurationData, assetsLiveData])

  //Recovery Assurance
  const recoveryAssurancePercentage = useMemo(() => {
    if (!assetsLiveData) {
      return undefined
    }

    const lastBackupsArray = Array.from(assetsLiveData.lastElastioBackupsMap)

    const totalBackups = lastBackupsArray.length

    const cleanBackups = lastBackupsArray.reduce(
      (cleanBackupsCount, [, backup]) => {
        if (
          (backup.ransomwareCheckStatus === CheckStatus.GOOD ||
            backup.ransomwareCheckStatus === CheckStatus.NOT_APPLICABLE) &&
          (backup.malwareCheckStatus === CheckStatus.GOOD ||
            backup.malwareCheckStatus === CheckStatus.NOT_APPLICABLE) &&
          (backup.fsCheckStatus === CheckStatus.GOOD ||
            backup.fsCheckStatus === CheckStatus.NOT_APPLICABLE)
        ) {
          return (cleanBackupsCount += 1)
        } else {
          return cleanBackupsCount
        }
      },
      0
    )

    return Math.round((cleanBackups / (totalBackups || 1)) * 100)
  }, [assetsLiveData])

  const theme = useTheme()

  const RadarchartRef = useRef(null)

  const isLoading =
    isAssetsLiveDataLoading ||
    isAwsScanCoverageDataLoading ||
    isOvaAssetsScannedDataLoading ||
    isGetFiltersForListAwsAssetSummariesDataLoading ||
    isAwsStorageMisconfiurationDataLoading

  useEffect(() => {
    if (!isLoading) {
      const loadResiliencePercentage = {
        assetCoveragePercentage: assetCoveragePercentage || 0,
        ransomwareSafetyPercentage: ransomwareSafetyPercentage || 0,
        encryptionIntegrityPercentage: encryptionIntegrityPercentage || 0,
        recoveryAssurancePercentage: recoveryAssurancePercentage || 0,
        storageHealthPercentage: storageHealthPercentage || 0,
      }
      setResiliencePercentage(loadResiliencePercentage)

      if (loadResiliencePercentage) {
        const score =
          (loadResiliencePercentage.assetCoveragePercentage *
            (resilienceWeight.assetCoverageWeight / 100) +
            loadResiliencePercentage.encryptionIntegrityPercentage *
              (resilienceWeight.encryptionIntegrityWeight / 100) +
            loadResiliencePercentage.ransomwareSafetyPercentage *
              (resilienceWeight.ransomwareSafetyWeight / 100) +
            loadResiliencePercentage.recoveryAssurancePercentage *
              (resilienceWeight.recoveryAssuranceWeight / 100) +
            loadResiliencePercentage.storageHealthPercentage *
              (resilienceWeight.storageHealthWeight / 100)) /
          5
        setResilienceScore(score)
      } else {
        const score =
          (resilienceWeight.assetCoverageWeight +
            resilienceWeight.encryptionIntegrityWeight +
            resilienceWeight.ransomwareSafetyWeight +
            resilienceWeight.recoveryAssuranceWeight +
            resilienceWeight.storageHealthWeight) /
          5
        setResilienceScore(score)
      }
    }
  }, [isLoading, resilienceWeight])

  useEffect(() => {
    if (!RadarchartRef.current) return
    let Radarchart = echarts.getInstanceByDom(RadarchartRef.current)

    if (!Radarchart) {
      Radarchart = echarts.init(RadarchartRef.current)
    }

    const indicators = [
      { name: '', max: 100 },
      { name: '', max: 100 },
      { name: '', max: 100 },
      { name: '', max: 100 },
      { name: '', max: 100 },
    ]

    const option = {
      title: {
        text: '',
      },

      radar: {
        shape: 'circle',
        splitNumber: 1,
        indicator: indicators,
        axisName: {
          color: '#B2B5C1',
          fontSize: 14,
          fontWeight: 400,

          rich: {
            a: {
              fontSize: 16,
              fontWeight: 400,
              align: 'center',
              cursor: 'pointer',
            },
          },
        },

        splitArea: {
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0.1, 0, 0.1, 1, [
              {
                offset: 0,
                color: 'rgba(117, 125, 132, 0.1)',
              },
              {
                offset: 1,
                color: 'rgba(117, 125, 132, 0.1)',
              },
              {
                offset: 0,
                color: 'rgba(117, 125, 132, 0.1)',
              },
            ]),
          },
        },
        splitLine: {
          lineStyle: {
            color: 'rgba(255,255,255,0.2)',
          },
        },
        axisLine: {
          lineStyle: {
            color: 'rgba(255,255,255,0.2)',
          },
        },
      },
      axisPointer: {
        show: true,
        lineStyle: {
          color: '#aaa',
          width: 1,
          type: 'dashed',
        },
      },
      series: [
        {
          type: 'radar',
          data: [
            {
              value: [
                recoveryAssurancePercentage ?? 0,
                assetCoveragePercentage ?? 0,
                ransomwareSafetyPercentage ?? 0,
                encryptionIntegrityPercentage ?? 0,
                storageHealthPercentage ?? 0,
              ],
              name: '360 Resilience',
              areaStyle: {
                normal: {
                  color: new echarts.graphic.RadialGradient(0.5, 0.5, 0.5, [
                    {
                      offset: 0,
                      color: 'rgba(0, 0, 0, 0)',
                    },
                    {
                      offset: 1,
                      color: 'rgba(41, 133, 220, 0.45)',
                    },
                  ]),
                },
                emphasis: {
                  color: '#87c0de',
                  opacity: 0.9,
                },
              },
              lineStyle: {
                color: 'rgba(0, 100, 193, 0.40)',
                width: 2,
              },
              symbol: 'circle',
              symbolSize: 0,
            },
          ],
        },
      ],
      graphic: [
        {
          type: 'image',
          left: 'center',
          top: 'center',
          z: 0,
          style: {
            image: ElastioLogo.src,
            width: 40,
            height: 40,
            opacity: 1,
          },
        },
        {
          type: 'text',
          left: '37%',
          top: '5%',
          color: '#fff',
          style: {
            text: '30% Recovery Assurance',
            fontSize: 16,
            cursor: 'pointer',
            fill:
              selectedSection === RadarChartAxes.RECOVERY_ASSURANCE
                ? '#80C5FF'
                : '#B2B5C1',
            textAlign: 'center',
            textBaseline: 'middle',
          },
          onclick: function () {
            setSelectedSection(RadarChartAxes.RECOVERY_ASSURANCE)
          },
        },
        {
          type: 'text',
          left: '8%',
          top: '35%',
          color: '#fff',
          style: {
            text: '30% \nStorage Health',
            fontSize: 16,
            cursor: 'pointer',
            fill:
              selectedSection === RadarChartAxes.STORAGE_HEALTH
                ? '#80C5FF'
                : '#B2B5C1',
            textAlign: 'right',
            textBaseline: 'middle',
          },
          onclick: function () {
            setSelectedSection(RadarChartAxes.STORAGE_HEALTH)
          },
        },
        {
          type: 'text',
          left: '76%',
          top: '35%',
          color: '#fff',
          style: {
            text: '30% \nAsset Coverage',
            fontSize: 16,
            cursor: 'pointer',
            fill:
              selectedSection === RadarChartAxes.ASSET_COVERAGE
                ? '#80C5FF'
                : '#B2B5C1',
            textAlign: 'left',
            textBaseline: 'middle',
          },
          onclick: function () {
            setSelectedSection(RadarChartAxes.ASSET_COVERAGE)
          },
        },
        {
          type: 'text',
          left: '12%',
          top: '80%',
          color: '#fff',
          style: {
            text: '30% \nEncryption Activity',
            fontSize: 16,
            cursor: 'pointer',
            fill:
              selectedSection === RadarChartAxes.ENCRYPTION_INTEGRITY
                ? '#80C5FF'
                : '#B2B5C1',
            textAlign: 'right',
            textBaseline: 'middle',
          },
          onclick: function () {
            setSelectedSection(RadarChartAxes.ENCRYPTION_INTEGRITY)
          },
        },
        {
          type: 'text',
          left: '68%',
          top: '80%',
          color: '#fff',
          style: {
            text: '30%\nRansomware Safety',
            fontSize: 16,
            cursor: 'pointer',
            fill:
              selectedSection === RadarChartAxes.RANSOMWARE_SAFETY
                ? '#80C5FF'
                : '#B2B5C1',
            textAlign: 'left',
            textBaseline: 'middle',
          },
          onclick: function () {
            setSelectedSection(RadarChartAxes.RANSOMWARE_SAFETY)
          },
        },
      ],
    }

    Radarchart.setOption(option)

    const handleResize = () => {
      Radarchart?.resize()
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
      // Radarchart.dispose()
    }
  }, [])

  useEffect(() => {
    if (!RadarchartRef.current) return
    const Radarchart = echarts.getInstanceByDom(RadarchartRef.current)

    if (Radarchart) {
      Radarchart.setOption({
        radar: {
          shape: 'circle',
          splitNumber: 1,

          splitArea: {
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0.1, 0, 0.1, 1, [
                {
                  offset: 0,
                  color: 'rgba(117, 125, 132, 0.1)',
                },
                {
                  offset: 1,
                  color: 'rgba(117, 125, 132, 0.1)',
                },
                {
                  offset: 0,
                  color: 'rgba(117, 125, 132, 0.1)',
                },
              ]),
            },
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(255,255,255,0.2)',
            },
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(255,255,255,0.2)',
            },
          },
        },
        axisPointer: {
          show: true,
          lineStyle: {
            color: '#aaa',
            width: 1,
            type: 'dashed',
          },
        },
        series: [
          {
            type: 'radar',
            data: [
              {
                value: isLoading
                  ? [0, 0, 0, 0, 0]
                  : [
                      recoveryAssurancePercentage ?? 0,
                      storageHealthPercentage ?? 0,
                      encryptionIntegrityPercentage ?? 0,
                      ransomwareSafetyPercentage ?? 0,
                      assetCoveragePercentage ?? 0,
                    ],
                name: '360 Resilience',
                areaStyle: {
                  normal: {
                    color: new echarts.graphic.RadialGradient(0.5, 0.5, 0.5, [
                      {
                        offset: 0,
                        color: 'rgba(0, 0, 0, 0)',
                      },
                      {
                        offset: 1,
                        color: 'rgba(41, 133, 220, 0.45)',
                      },
                    ]),
                  },
                  emphasis: {
                    color: '#87c0de',
                    opacity: 0.9,
                  },
                },
                lineStyle: {
                  color: 'rgba(0, 100, 193, 0.40)',
                  width: 2,
                },
                symbol: 'circle',
                symbolSize: 0,
              },
            ],
          },
        ],
        graphic: [
          {
            type: 'image',
            left: 'center',
            top: 'center',
            z: 0,
            style: {
              image: ElastioLogo.src,
              width: 40,
              height: 40,
              opacity: 1,
            },
          },
          {
            type: 'text',
            left: '37%',
            top: '5%',
            color: '#fff',
            style: {
              text: `${recoveryAssurancePercentage ?? 0}% Recovery Assurance`,
              fontSize: 16,
              cursor: 'pointer',
              fill:
                selectedSection === RadarChartAxes.RECOVERY_ASSURANCE
                  ? '#80C5FF'
                  : '#B2B5C1',
              textAlign: 'center',
              textBaseline: 'middle',
            },
            onclick: function () {
              setSelectedSection(RadarChartAxes.RECOVERY_ASSURANCE)
            },
          },
          {
            type: 'text',
            left: '8%',
            top: '35%',
            color: '#fff',
            style: {
              text: `${storageHealthPercentage ?? 0}% \nStorage Health`,
              fontSize: 16,
              cursor: 'pointer',
              fill:
                selectedSection === RadarChartAxes.STORAGE_HEALTH
                  ? '#80C5FF'
                  : '#B2B5C1',
              textAlign: 'right',
              textBaseline: 'middle',
            },
            onclick: function () {
              setSelectedSection(RadarChartAxes.STORAGE_HEALTH)
            },
          },
          {
            type: 'text',
            left: '76%',
            top: '35%',
            color: '#fff',
            style: {
              text: `${assetCoveragePercentage ?? 0}% \nAsset Coverage`,
              fontSize: 16,
              cursor: 'pointer',
              fill:
                selectedSection === RadarChartAxes.ASSET_COVERAGE
                  ? '#80C5FF'
                  : '#B2B5C1',
              textAlign: 'left',
              textBaseline: 'middle',
            },
            onclick: function () {
              setSelectedSection(RadarChartAxes.ASSET_COVERAGE)
            },
          },
          {
            type: 'text',
            left: '12%',
            top: '80%',
            color: '#fff',
            style: {
              text: `${encryptionIntegrityPercentage ?? 0}% \nEncryption Activity`,
              fontSize: 16,
              cursor: 'pointer',
              fill:
                selectedSection === RadarChartAxes.ENCRYPTION_INTEGRITY
                  ? '#80C5FF'
                  : '#B2B5C1',
              textAlign: 'right',
              textBaseline: 'middle',
            },
            onclick: function () {
              setSelectedSection(RadarChartAxes.ENCRYPTION_INTEGRITY)
            },
          },
          {
            type: 'text',
            left: '68%',
            top: '80%',
            color: '#fff',
            style: {
              text: `${ransomwareSafetyPercentage ?? 0}%\nRansomware Safety`,
              fontSize: 16,
              cursor: 'pointer',
              fill:
                (ransomwareSafetyPercentage ?? 0) < 100
                  ? selectedSection === RadarChartAxes.RANSOMWARE_SAFETY
                    ? '#FF1654' // can change this if need to update active color when there are infected assets
                    : '#FF1654'
                  : selectedSection === RadarChartAxes.RANSOMWARE_SAFETY
                    ? '#80C5FF'
                    : '#B2B5C1',
              textAlign: 'left',
              textBaseline: 'middle',
            },
            onclick: function () {
              setSelectedSection(RadarChartAxes.RANSOMWARE_SAFETY)
            },
          },
        ],
      })
    }
  }, [
    assetCoveragePercentage,
    ransomwareSafetyPercentage,
    encryptionIntegrityPercentage,
    recoveryAssurancePercentage,
    storageHealthPercentage,
    selectedSection,
    isLoading,
  ])

  const handleMouseEnter = () => {
    setHovered(true)
  }
  const handleMouseLeave = () => {
    setHovered(false)
  }

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: 400,
      }}
    >
      <div
        ref={RadarchartRef}
        style={{
          width: '100%',
          height: '100%',
          cursor: 'pointer',
        }}
      />
      <Box
        className="ui-v2-rotating-overlay"
        sx={{
          opacity: theme.palette.mode === 'dark' ? 1 : 0.3,
          '&:after': {
            background:
              theme.palette.mode === 'dark'
                ? 'radial-gradient(rgb(255 0 0 / 40%), rgba(0, 100, 193, 0.007) 70%)'
                : 'rgba(117, 125, 132, 0.2)',
          },
        }}
      ></Box>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
          zIndex: 9,
          opacity: hovered ? 1 : 0,
          padding: '90px',
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Typography fontWeight={700} fontSize={32} mb={1} color="#fff">
          {Math.round(resilienceScore)}%
        </Typography>
        {hovered && (
          <Button
            variant="contained"
            sx={{
              textTransform: 'capitalize',
              borderRadius: '50px',
            }}
            size="small"
            onClick={() => setOpenScoreCalculationDrawer(true)}
          >
            View Calculation
          </Button>
        )}
      </Box>
    </Box>
  )
}
